@import "variables";
@import "_mixins";

.sortable {
  width: 425px;
  min-width: 425px;
  flex: 1;
  max-width: 100%;
  height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;

  &.two-column {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
  }
}

.trail {
  @include flex-simple-column;
  width: 100%;
  padding: 0 0 25px 0;

  .routeName {
    font-family: "Lato Bold";
    font-size: 1.6rem;
    color: $black;
    display: flex;
    .trailerType {
      font-family: "Lato Regular";
      margin-left: 5px;
    }
    label {
      margin-right: 8px;
      margin-left: auto;
      cursor: pointer;
      .visibleImg,
      .invisibleImg {
        height: 13px;
        width: 18px;
        padding: 3px;
        background-color: $white;
        display: inline-block;
        background-repeat: no-repeat;
        &:before {
          position: relative;
          text-align: right;
          vertical-align: top;
          font-family: "Lato Italic";
          font-size: 1.4rem;
          letter-spacing: 0px;
          color: rgba(29, 29, 27, 0.7);
        }
      }
      .visibleImg {
        background-image: url("../images/ico_visible.svg");
        background-size: 18px 13px;
        background-position: 3px 3px;
        &:before {
          right: 45px;
          bottom: 3px;
          content: "visible";
        }
        &:hover {
          background-image: url("../images/ico_invisible.svg");
          background-size: 19px 14px;
          background-position: 2px 3px;
          &:before {
            right: 55px;
            bottom: 3px;
            content: "invisible";
          }
        }
      }
      .invisibleImg {
        background-image: url("../images/ico_invisible.svg");
        background-size: 19px 14px;
        background-position: 3px 3px;

        &:before {
          right: 55px;
          bottom: 3px;
          content: "invisible";
        }

        &:hover {
          background-image: url("../images/ico_visible.svg");
          background-size: 18px 13px;
          background-position: 3px 3px;

          &:before {
            right: 45px;
            bottom: 3px;
            content: "visible";
          }
        }
      }
    }
    .routeIsShown {
      display: none;
    }
  }
}

.warningImg {
  height: 26px;
  width: 16px;
  background-image: url("../images/ico_warning.svg");
  background-size: auto 50%;
  background-position: center;
  background-repeat: no-repeat;
  display: inline;
}

.routeParams {
  display: grid;
  padding: 8px 5px 5px 5px;
  font-size: 1.6rem;

  .parameter {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 5px 20px 5px;
    font-family: "Lato Regular";
    color: $black;
    &:nth-child(3):last-child {
      grid-column: span 2;
    }
    &:last-of-type {
      font-family: "Lato Black";
    }
  }
}

.warning_order {
  background: $light-yellow !important;
  border: 2px solid $yellow !important;
}

.order {
  position: relative;
  @include flex-centered-items;
  border: 2px solid #e1dfdf;
  margin: 3px;
  background: $white;
  padding: 7px 26px 7px 0;
  box-sizing: border-box;
  overflow: hidden;

  .warningImg {
    display: inline-block;
    background-position: bottom;
    margin-top: -10px;
  }

  .colorIndicator {
    @include position-absolute($top: 5px, $left: 5px);
    display: inline-block;
    width: 3px;
    height: calc(100% - 10px);
    background: white;
  }

  &:after {
    @include position-absolute($top: 5px, $right: 5px);
    content: "";
    width: 15px;
    height: calc(100% - 10px);
    background: transparent url("../images/grab.svg") no-repeat right center;
  }

  &.no-after:after {
    content: none;
  }

  &.sortable-chosen:after {
    background: transparent url("../images/grab_active.svg") no-repeat right
      center;
  }

  &.sortable-chosen {
    border-color: #0164c7;
  }

  &:hover {
    border-color: #0164c7;
    &:after {
      background: transparent url("../images/grab_active.svg") no-repeat right
        center;
    }
  }

  > div.order_number {
    padding: 0 16px 0 24px;
    font-family: "Lato Black";
    font-size: 1.4rem;
    color: #e1dfdf;
  }

  .order_info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 6px;
    grid-column-gap: 20px;
    width: 100%;

    &.flex {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .order_info_item:nth-child(2) {
        grid-column: initial;
      }

      .order_info_item:nth-child(5) {
        grid-column: 2 / span 2;
      }

      .order_info_item:first-child {
        grid-column: initial;
        grid-row: 1 / 3;
      }
    }

    .order_info_item {
      @include flex-centered-items;

      .icon {
        margin-right: 7px;
        font-size: 14px;
        opacity: 0.5;
      }

      &:first-child {
        grid-column: 1 / span 4;

        .value {
          font-family: "Lato Medium";
          font-size: 1.8rem;
          color: $black;

          span.red {
            color: $red;
          }
        }
      }

      &:nth-child(2) {
        grid-column: 1 / span 2;
      }

      &:nth-child(5) {
        grid-column: 1 / span 3;
      }

      .value {
        font-family: "Lato Bold";
        font-size: 1.4rem;
        color: $black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span.small {
          font-family: "Lato Medium";
          color: rgba($black, 0.5);
        }
      }
    }
  }

  .choices-detail-container {
    @include position-absolute($top: 0, $right: -100%);
    @include flex-align-right-items;
    visibility: hidden;
    opacity: 0;
    right: 0;
    padding-right: 30px;
    height: 100%;
    width: 50%;
    transition:
      visibility 0s,
      opacity 0.2s linear;
    background: rgb(248, 248, 248);
    background: linear-gradient(
      90deg,
      rgba(248, 248, 248, 0) 0%,
      rgba(248, 248, 248, 1) 100%
    );

    .choices__detail {
      font-family: "Lato Black Italic";
      background-color: $blue_second;
      height: 2.5rem;
      width: 2.5rem;
      border: none;
      border-radius: 4px;
      color: $white;
      cursor: pointer;
      flex-shrink: 0;
      z-index: 999;
    }
  }

  &:hover {
    .choices-detail-container {
      visibility: visible;
      opacity: 1;
      pointer-events: painted;
    }
  }
}

.placeholder {
  position: relative;
  @include flex-centered-items;
  border: 6px solid #e1dfdf;
  margin: 3px;
  background: $white;
  padding: 7px 40px 7px 0;
  box-sizing: border-box;
  height: 88px;
}
.addTrailer {
  position: relative;
  @include flex-centered-items;
  border: 2px solid #e1dfdf;
  margin: 3px;
  background: $white;
  justify-content: center;
  box-sizing: border-box;
  height: 88px;
}
.customPopup {
  .leaflet-popup-content-wrapper {
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    line-height: 1.4;
    white-space: normal;
    outline: 0;
    font-size: 1.4rem;
    font-weight: bold;
    .leaflet-popup-content {
      cursor: pointer;
    }
  }
  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      background-color: #333;
    }
  }
}

.leaflet-routing-container {
  display: none;
}

.leaflet-extend-zoom,
.leaflet-visible {
  position: absolute;
  left: 10px;
  margin-left: 12px;
  z-index: 1000;
  height: 20px;
  width: 30px;
  text-align: center;
  color: $white !important;
  background-color: #0164c7;
  cursor: pointer;
  border-radius: 2px;
}

.leaflet-extend-zoom {
  padding: 7px 0 3px 0;
  top: 575px;
}

.visible-all {
  padding: 5px 0;
  top: 20px;
  background-image: url("../images/ico_visible_white.svg");
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: 6px 5px;
}

.invisible-all {
  padding: 5px 0;
  top: 20px;
  background-image: url("../images/ico_invisible_white.svg");
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: 6px 5px;
}

.split_order {
  height: 2.5rem;
  width: 2.5rem;
  border: none;
  border-radius: 4px;
  background-color: $blue_second;
  background-image: url("../images/ico_split.svg");
  background-size: 2rem 2rem;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
}

.leaflet-control-zoom-out,
.leaflet-control-zoom-in {
  color: $white !important;
  background-color: #0164c7 !important;
  position: absolute;
}

.leaflet-control-zoom-in {
  top: 600px;
  margin-left: 10px;
}

.leaflet-control-zoom-out {
  top: 632px;
  margin-left: 10px;
}

.blue-frames {
  border: 2px solid #0164c7;
}

.map-container {
  position: relative;
  width: 100%;
  min-width: 10px;
  min-height: 700px;
  border: 5px solid $white;

  .leaflet-container {
    z-index: 0;
  }
}

.trip-map-container {
  min-width: 400px !important;
  min-height: 100% !important;
}

.move-bar {
  @include flex-column-space-around;
  @include position-absolute($top: 0, $left: 0);
  width: 10px;
  height: 100%;
  background: $light-blue;
  z-index: 999;

  &.dashboard {
    background: $white;
    &:hover {
      background-color: $white;
    }
  }

  &:hover {
    background: $black;

    .dots-container .dot {
      background: #fff;
    }
  }

  .toggle-map-btn {
    @include flex-full-centered-items;
    @include border-radius($right-top: 4px, $right-bottom: 4px);
    width: 100%;
    height: 38px;
    background: $blue;

    &.dashboard {
      margin-top: 8px;
      width: 200px !important;
      height: 8px !important;
      border: 2px solid rgba(255, 255, 255, 1);
      border-radius: 33px !important;
    }

    > span {
      color: #fff;
      font-size: 10px;
    }
    &.map-closed {
      > span {
        transform: rotate(180deg);
      }
    }
  }

  .dots-container {
    @include flex-space-between-column;
    width: 100%;
    height: 34px;

    .dot {
      @include border-radius(50%);
      display: inline-block;
      height: 5px;
      width: 5px;
      background-color: $blue;
    }
  }

  &.horizontal {
    position: initial;
    flex-direction: row;
    width: 100%;
    height: 20px;

    .toggle-map-btn {
      @include border-radius(
        $left_top: 4px,
        $right_top: 4px,
        $left_bottom: 0,
        $right_bottom: 0
      );
      width: 38px;
      height: 100%;
      flex-shrink: 0;
    }

    .dots-container {
      flex-direction: row;
      width: 34px;
      height: 100%;
    }
  }
}

.edit-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .map-dashboard-label {
    font-size: 2.6rem;
  }
  .map-dashboard-table {
    max-height: 90%;
    overflow: scroll;
    min-width: 80%;
    .trip-list {
      margin-bottom: 15px;
      padding: 20px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(241, 242, 244, 1);
      border-radius: 26px;
      opacity: 1;
      .trip-list-title {
        font-family: "Lato Bold";
        display: flex;
        font-size: 1.8rem;
        margin-bottom: 8px;
        .trip-header {
          margin-right: 4px;
        }
        .trailer-type {
          font-family: "Lato Regular";
        }
        .trip-date {
          font-size: 1.6rem;
          align-self: flex-end;
          margin: 0 9px 0 20px;
        }
      }
      .trip-list-info {
        display: flex;
        padding: 20px;
        font-size: 1.6rem;
        background: rgba(248, 248, 248, 1);
        .trip-first-column {
          flex-grow: 1;
          margin-right: 20px;
        }
        .trip-second-column {
          flex-grow: 2;
        }
        .trip-label {
          font-size: 1.4rem;
          letter-spacing: 0px;
          color: rgba(29, 29, 27, 0.5);
        }
        .trip-number {
          font-size: 1.6rem;
          font-family: "Lato Bold";
        }
      }
      .trip-list-buttons {
        display: flex;
        justify-content: space-between;
      }
      .trip-list-button,
      .trip-form-button {
        @include flex-full-centered-items;
        @include border-radius(30px);
        height: 35px;
        width: 105px;
        font-family: "Lato Bold";
        font-size: 1.5rem;
        color: #fff;
        background: $black;
        border: 3px solid transparent;
        box-shadow: none;
        margin-top: 20px;

        &:hover {
          background: $blue;
          cursor: pointer;
        }
      }
    }
  }
}

.leaflet-div-icon {
  border: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 66px;
  height: 30px;
  margin-left: auto;
  margin-right: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(229, 239, 249, 1);
  border: 2px solid rgba(176, 206, 236, 1);
  border-radius: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:hover {
    &:before {
      background-color: rgba(1, 100, 199, 1);
    }
  }
}

.slider.disabled {
  background-color: rgba(248, 248, 248, 1);
  border: 2px solid rgba(225, 223, 223, 1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  top: -2px;
  left: -2px;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(176, 206, 236, 1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.disabled:before {
  background-color: rgba(248, 248, 248, 1);
  border: 2px solid rgba(225, 223, 223, 1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.map-advanced-label {
  text-align: right;
  font-family: "Lato Italic";
  font-size: 1.6rem;
  letter-spacing: 0px;
  color: rgba(29, 29, 27, 0.7);
  margin-right: 14px;
}

.map-advanced-container {
  display: flex;
  margin-bottom: 11px;
}

.map-advanced {
  margin-left: auto;
  margin-right: 0;
}

.tippy-box {
  max-width: initial !important;
}

#temporaryStorage {
  border-radius: 12px;
  background: $yellow;
  border: 4px solid white;
  margin: 0 10px;

  .title {
    text-align: center;
    padding: 1rem;
    font-family: "Lato Black";
    font-size: 1.3rem;
    color: $black;
  }
  .sortable {
    height: 660px;
    background: white;
    border: 2px solid $yellow;
    border-radius: 0 0 6px 6px;
    background-image: url(../images/ico_temporary_storage.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}

.move_to_temporary_storage {
  height: 2.5rem;
  width: 2.5rem;
  border: none;
  border-radius: 4px;
  background-color: $blue_second;
  background-image: url("../images/ico_temporary_storage.svg");
  background-size: 1.6rem 1.6rem;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

nav.mobile-view-nav {
  @include position-absolute($bottom: 0, $left: 0);
  width: 100%;
  height: 75px;
  background: $light-gray;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0 60px;
    margin: 10px auto;
    li {
      display: inline-block;
      text-align: center;
      div {
        text-decoration: none;
        cursor: pointer;
        color: rgba(29, 29, 27, 0.8);
        .label {
          text-align: center;
          font-family: "Lato Regular";
          font-size: 1.2rem;
          max-width: 60px;
        }
      }
    }
  }
}

.desktop-view-nav {
  display: flex;
  border-bottom: 7px solid rgba(38, 127, 83, 0.1);
  div {
    cursor: pointer;
    display: flex;
    margin-left: 27px;
    width: 313px;
    height: 75px;
    align-items: center;
    div {
      border: none;
      margin-left: 14px;
      padding-top: 8px;
    }
  }
}

.map-main-view {
  display: flex;
  min-height: 90%;
}

.view-trails {
  background: $white;

  .routeName {
    @include flex-centered-items;
    padding: 10px 0;
    margin: 0 10px;
  }

  .order-container {
    @include flex-simple-column;
    @include border-radius(26px);
    margin: 0 10px;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 18px;
    margin-bottom: 8px;

    &.dashboard {
      margin: 10px auto;
      max-width: 370px;
      border: 1px solid rgba(241, 242, 244, 1);
      border-radius: 26px;
      padding: 35px 10px 18px 10px;
    }

    .order {
      position: relative;
      overflow: visible;

      .order-status {
        @include flex-full-centered-items;
        @include position-absolute($top: -30px, $left: 0);
        @include border-radius($left-top: 11px, $right-top: 11px);
        height: 22px;
        padding: 0 74px 0 18px;
        background: rgba(243, 214, 66, 1);
        font-family: "Lato Black";
        font-size: 1.2rem;
        color: rgba(29, 29, 27, 1);
        text-transform: uppercase;
      }

      .order_number {
        font-family: "Lato Black";
        font-size: 1.4rem;
        color: $green;
      }

      .order_info {
        display: flex;
        flex-wrap: wrap;

        .order_info_item {
          font-family: "Lato Bold";
          font-size: 1.4rem;

          &.address {
            flex-basis: 100%;
            font-family: "Lato Medium";
            font-size: 1.8rem;
            color: $green;
          }
        }
      }
    }

    button {
      align-self: flex-end;
      font-size: 1.6rem;
      margin-top: 15px;
      margin-right: 20px;
      padding: 8px 38px;
    }
  }
}

.mobile-view-trails {
  @include position-absolute($left: 0, $bottom: 75px);
  width: 100%;
  height: auto;
  min-width: auto;

  .routeName {
    @include flex-full-centered-items;
    margin: 0;
  }
}

.no-visible {
  visibility: hidden;
}
