@import "variables";
@import "_mixins";

#topbar {
  width: 100%;
  height: 10rem;
  background-color: $black;
  color: $white;
  box-shadow: 0px 8px 9px $shadow-color;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .branding {
      margin-left: 8rem;

      a {
        color: $white;

        img {
          height: 56px;

          @include media-layout("small") {
            height: 35px;
          }
        }
      }
    }

    .links {
      display: flex;
      margin-right: 8rem;

      .topbar-item {
        color: $white;

        a {
          color: inherit;
          text-decoration: none;
        }

        &:hover {
          background-color: $white;
          color: $black;
        }
      }
    }
  }

  @include media-layout("small") {
    height: 70px;
  }
}

#middle {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;

  #sidebar {
    @include flex-centered-column;
    flex-shrink: 0;
    width: 270px;
    background-color: #fff;
    transition: width 0.05s;
    z-index: 10;
    box-shadow: 0px 3px 17px #0000000a;

    .sidebar-menu-container {
      height: calc(100% - 3rem);
      width: 270px;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .logo-container {
        background-color: #000000;
        width: 100%;
        height: 7.6rem;
        flex-shrink: 0;
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          padding-left: 2.8rem;
          img {
            object-fit: contain;
            height: 3.1rem;
            width: 18.7rem;
          }
        }
      }

      .hamburger {
        @include border-radius(2px);
        width: 50px;
        height: 50px;
        background: #fff;
        border: 0;
        color: $black;
        overflow: hidden;
        display: none;
        margin: 5px 16px;
        position: relative;
        flex-shrink: 0;

        &:hover {
          cursor: pointer;
          background-color: $mercury;
        }
      }

      nav {
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        padding-bottom: 5rem;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: transparent;
        }

        &:hover::-webkit-scrollbar-thumb {
          background-color: $cod-gray;
        }

        .menu-category-container {
          display: flex;
          flex-direction: column;
          gap: 1.1rem;
          padding-top: 1.6rem;
          width: calc(100% - 17px);
          position: relative;

          &::after {
            display: inline-block;
            content: "";
            height: 0.1rem;
            position: absolute;
            bottom: -18px;
            right: 0;
            left: 20px;
            background-color: $mercury;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            li {
              &:not(:last-child) {
                margin-bottom: 6px;
              }

              .btn-menu-item {
                @include flex-centered-items;
                gap: 16px;
                min-height: 28px;
                padding: 5px 20px 5px 29px;
                font-family: "Lato Regular";
                font-size: 1.8rem;
                line-height: 2.2rem;
                color: $mine-shaft;
                text-decoration: none;
                border: none;
                border-radius: 0px 4px 4px 0px;
                cursor: pointer;

                &:hover {
                  background-color: $light-gray;
                }
              }

              .accent {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
              }

              form {
                display: flex;
                justify-content: center;
                position: relative;
                margin-top: 17px;
                padding-top: 15px;

                &::after {
                  display: inline-block;
                  content: "";
                  height: 0.1rem;
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 20px;
                  background-color: $mercury;
                }

                .btn-menu-item {
                  width: 100%;
                  background-color: transparent;
                }
              }
            }
          }
        }

        .menu-category-title {
          color: $mine-shaft;
          font-family: "Lato Bold";
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding-left: 28px;
        }

        .menu-category-title-icon {
          display: none;
          width: 2.5rem;
          height: 2.5rem;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          padding-left: 58px;

          &.title-icon-processing::before {
            display: inline-block;
            content: "";
            height: 0.1rem;
            position: absolute;
            top: 2px;
            right: 0;
            left: 11px;
            right: 0;
            background-color: $mercury;
          }

          &.title-icon-settings {
            background-image: url(../images/sidebar/settings.svg);
          }

          &.title-icon-processing {
            background-image: url(../images/sidebar/processing.svg);
          }
        }
      }

      .toggle-sidebar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $mine-shaft;
        border: 0;
        cursor: pointer;
        font-family: "Lato Regular";
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding: 0;
        width: 100%;
        background-color: transparent;
        padding: 20px 27px 21px 27px;
        position: relative;

        &::after {
          display: inline-block;
          content: "";
          height: 0.1rem;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 20px;
          background-color: $mercury;
        }

        &:hover {
          background: #e1dfdf;
          cursor: pointer;
        }
      }

      .toggle-sidebar-icon {
        display: inline-block;
        transform: translateY(0.1rem);
        width: 2.3rem;
        height: 1.8rem;
        background: center no-repeat url(../images/sidebar/sidebar-chevron.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &.hidden {
      @include media-layout("769px", "min-width") {
        width: 84px;
        .sidebar-menu-container {
          width: 84px;

          .logo-container {
            a {
              padding-left: 0;
              justify-content: center;

              img {
                width: 35px;
                object-fit: cover;
                object-position: 0% 50%;
              }
            }
          }

          .toggle-sidebar {
            display: none;
          }

          .hamburger {
            display: block;
          }
        }
      }

      @media (min-width: 769px) and (min-height: 350px) {
        nav .menu-category-container {
          padding-top: 13px;
          width: calc(100% - 12px);
        }

        nav .menu-category-title {
          display: none;
        }

        nav .menu-category-title-icon {
          display: inline-block;
        }

        nav .menu-category-container ul li .btn-menu-item {
          padding-left: 24px;
          padding-right: 8px;
          justify-content: center;
        }

        nav .menu-category-container::after {
          left: 12px;
        }

        nav .menu-category-container ul li form::after {
          left: 12px;
        }

        nav li span {
          display: none;
        }
      }

      @include media-layout(350px, "max-height") {
        width: 100%;
        .sidebar-menu-container {
          width: 100%;
          align-items: center;

          .hamburger {
            margin-left: 0;
            margin-right: 0;
          }

          .logo-container {
            height: 70px;
            a {
              padding-left: 0;

              img {
                width: 212px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    @include media-layout("small", $extra_query: "max-height: 350px") {
      @include position-absolute($top: 0, $left: 0);
      width: 100%;
      box-sizing: border-box;
      z-index: 999;

      .sidebar-menu-container {
        position: static;
        width: 100%;
        height: 70px;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 30px;
        background: #000;
        box-sizing: border-box;
        align-items: center;

        .hamburger {
          margin-left: 0;
          margin-right: 0;
        }

        .logo-container {
          width: auto;
          height: 70px;

          a {
            padding-left: 0;
          }

          img {
            width: 212px;
            height: 35px;
            object-fit: cover;
          }
        }

        .hamburger {
          display: block;
          position: static;
          width: 35px;
          height: 35px;
        }

        nav {
          position: fixed;
          top: 70px;
          left: 0;
          right: 0;
          bottom: 0;
          display: none;

          &.open {
            display: flex;
            width: 100%;
            background: $light-gray;
            box-sizing: border-box;
            padding: 5rem 1rem;
          }
        }

        .toggle-sidebar {
          display: none;
        }
      }
    }
  }

  main.content {
    display: flex;
    flex-grow: 1;
    padding: 34px 90px 43px 70px;
    overflow: hidden;

    .centered-wrapper {
      @include flex-inline-column;
      align-self: center;
      margin: 0 auto;
    }

    @include media-layout("small", $extra_query: "max-height: 350px") {
      padding-top: 120px;
    }
  }
}

#footer {
  font-family: "Lato Regular";
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b8d3c6;
  height: 3rem;
  color: black;
  z-index: 101;

  span {
    opacity: 0.7;
  }
}

.captcha-container {
  @include flex-full-centered-items;

  input {
    width: 150px !important;
  }

  .captcha-main {
    @include flex-centered-items;
    flex-grow: 1;

    .captcha-buttons {
      @include flex-inline-column;

      #resetCaptcha {
        margin-bottom: 3px;
      }
    }

    img {
      flex-grow: 1;
    }
  }
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.content > h1 {
  @extend %h1;

  &.space-between {
    justify-content: space-between;
  }
}

.switch-view-container {
  @include flex-centered-items;
}

.flex {
  @include flex-centered-items;
}

.flex-inline-centered {
  @include flex-inline-centered;
}

.flex-full-centered-items {
  @include flex-full-centered-items;
}

.flex-align-right-items {
  @include flex-align-right-items;
}

.flex-space-between {
  @include flex-space-between;
}

.flex-space-between-centered {
  @include flex-space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
  row-gap: 10px;
}

.table-buttons {
  margin-top: 32px;
}

.detail-container {
  max-width: 720px;
  width: 100%;

  .form-container {
    padding: 0;
    box-shadow: 0px 30px 30px #267f534a;

    .form-main-content {
      padding: 46px 159px;
    }
  }
}

.data-cell {
  &.span-2 {
    grid-column: 1 / 3;
  }

  .data-cell-label {
    font-family: "Lato Medium";
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  .data-cell-content {
    font-family: "Lato Bold";
    font-size: 1.6rem;

    &.break-word {
      word-break: break-all;
    }
  }
}

.data-two-column-layout {
  @include flex-space-between;

  &.with-bg {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 3.6rem;
    background: $detail-bg-color;
    padding: 1.6rem;
    &.dashboard {
      margin-top: 20px;
      grid-column-gap: 1rem;
      grid-row-gap: 0;
      .data-cell {
        margin-bottom: 5px;
        &.right {
          text-align: right;
        }
        .data-cell-label {
          margin: 0;
        }
        .data-cell-content {
          font-size: 1.4rem;
        }
      }
    }
  }

  &.grid-style {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 6rem;
    grid-row-gap: 3.6rem;
  }
}

.data-checkbox-container {
  @include flex-space-around;

  &.single-checkbox {
    justify-content: initial;
  }

  .data-checkbox-item {
    i {
      font-size: 1.6rem;
      color: $green;
      margin-right: 23px;
    }

    span {
      font-family: "Lato Bold";
      font-size: 1.6rem;
    }
  }

  &.two-column-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 3.6rem;
  }
}

.rate-amount {
  font-family: "Lato Bold";
  font-size: 3rem;
}

.side-navigation {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  right: 3.5rem;
  top: 50%;
  transform: translate(0, -50%);

  a {
    width: 1.6rem;
    height: 3rem;
    border-radius: 1rem;
    background-color: $green;
    border: 3px solid #ffffff;

    &.active,
    &:hover {
      background-color: $blue;
      box-shadow: 0px 3px 6px $shadow-color;
    }
  }

  @include media-layout("small") {
    display: none;
  }
}

.btn-menu-item-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  .btn-menu-item.menu-orders & {
    background-image: url(../images/sidebar/orders.svg);
  }

  .btn-menu-item.menu-localtrips & {
    background-image: url(../images/sidebar/local-trips.svg);
  }

  .btn-menu-item.menu-trips & {
    background-image: url(../images/sidebar/trips.svg);
  }

  .btn-menu-item.menu-optimizations & {
    background-image: url(../images/sidebar/optimizations.svg);
  }

  .btn-menu-item.menu-customers & {
    background-image: url(../images/sidebar/customers.svg);
  }

  .btn-menu-item.menu-billing & {
    background-image: url(../images/sidebar/billings.svg);
  }

  .btn-menu-item.menu-drivers & {
    background-image: url(../images/sidebar/drivers.svg);
  }

  .btn-menu-item.menu-trailers & {
    background-image: url(../images/sidebar/trailers.svg);
  }

  .btn-menu-item.menu-trucks & {
    background-image: url(../images/sidebar/trucks.svg);
  }

  .btn-menu-item.menu-groups & {
    background-image: url(../images/sidebar/groups.svg);
  }

  .btn-menu-item.menu-notifications & {
    background-image: url(../images/sidebar/notifications.svg);
  }

  .btn-menu-item.menu-hubs & {
    background-image: url(../images/sidebar/hubs.svg);
  }

  .btn-menu-item.menu-terminals & {
    background-image: url(../images/sidebar/terminals.svg);
  }

  .btn-menu-item.menu-cost-matrix & {
    background-image: url(../images/sidebar/cost-matrix.svg);
  }

  .btn-menu-item.menu-billing-department & {
    background-image: url(../images/sidebar/billing-department.svg);
  }

  .btn-menu-item.menu-users & {
    background-image: url(../images/sidebar/users.svg);
  }

  .btn-menu-item.menu-units & {
    background-image: url(../images/sidebar/units.svg);
  }

  .btn-menu-item.menu-cost & {
    background-image: url(../images/sidebar/cost.svg);
  }

  .btn-menu-item.menu-history & {
    background-image: url(../images/sidebar/history.svg);
  }

  .btn-menu-item.menu-locations & {
    background-image: url(../images/sidebar/groups.svg);
  }

  .btn-menu-item.menu-profile & {
    background-image: url(../images/sidebar/profile.svg);
  }

  .btn-menu-item.btn-sign-out & {
    background-image: url(../images/sidebar/sign-out.svg);
  }
}

body.menu-orders .btn-menu-item.menu-orders,
body.menu-trips .btn-menu-item.menu-trips,
body.menu-localtrips .btn-menu-item.menu-localtrips,
body.menu-optimizations .btn-menu-item.menu-optimizations,
body.menu-customers .btn-menu-item.menu-customers,
body.menu-drivers .btn-menu-item.menu-drivers,
body.menu-trailers .btn-menu-item.menu-trailers,
body.menu-trucks .btn-menu-item.menu-trucks,
body.menu-groups .btn-menu-item.menu-groups,
body.menu-hubs .btn-menu-item.menu-hubs,
body.menu-terminals .btn-menu-item.menu-terminals,
body.menu-cost-matrix .btn-menu-item.menu-cost-matrix,
body.menu-units .btn-menu-item.menu-units,
body.menu-cost .btn-menu-item.menu-cost,
body.menu-users .btn-menu-item.menu-users,
body.menu-notifications .btn-menu-item.menu-notifications,
body.menu-history .btn-menu-item.menu-history,
body.menu-billing .btn-menu-item.menu-billing,
body.menu-billing-department .btn-menu-item.menu-billing-department,
body.menu-locations .btn-menu-item.menu-locations,
body.menu-profile .btn-menu-item.menu-profile {
  background-color: $mercury-dark;
  position: relative;

  .accent {
    width: 4px;
    background-color: $mariner;
  }
}

.higlighted-holiday {
  color: $red;
  font-family: "Lato Bold";
}
