@import "variables";

.bell-container {
  position: relative;
  top: 34px;
  right: 90px;
  z-index: 999;

  .slide-container {
    position: absolute;
    right: 0;
    width: 180px;
    height: 40px;
    border-radius: 30px;
    overflow: hidden;

    .slide {
      position: absolute;
      right: -180px;
      width: 180px;
      height: 40px;
      color: $white;
      padding: 9px 14px 0 0;
      text-align: center;
      font-family: "Lato Regular";
      font-size: 1.8rem;
      letter-spacing: 0;
      background: $blue;
      border-radius: 30px;
      cursor: pointer;
      transition: 0.3s;

      &.hover {
        transition: 0.3s;
        right: 0;
      }
    }
  }
  #Component_146_3 {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  #notifications_badge {
    z-index: 1;
    position: absolute;
    right: 15px;
    top: -15px;
    cursor: pointer;
    pointer-events: none;
  }

  @include media-layout("small", $extra_query: "max-height: 350px") {
    top: 116px;
    right: 30px;
  }
}

.notifications-container {
  position: absolute;
  top: 80px;
  right: 90px;
  padding: 19px;
  z-index: 1;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(241, 242, 244, 1);
  border-radius: 26px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  .live_notify_list {
    max-width: 419px;
    max-height: 267px;
    overflow: auto;
    padding: 0;
    li {
      list-style-type: none;
      margin-bottom: 19px;
      background: rgba(247, 250, 253, 1);
      border: 2px solid rgba(255, 255, 255, 1);
      border-radius: 6px;
      padding: 15px;
      .notification-timestamp-label {
        color: $black;
      }
      .notification-label {
        font-style: italic;
        color: $black;
      }
      .notification-target-label {
        a {
          cursor: pointer;
          color: rgba(1, 100, 199, 1);
        }
      }
    }
  }
  #notifications-clear-button {
    background-color: rgba(255, 255, 255, 1);
  }
  #notifications-more-button {
    float: right;
    &:hover {
      background-color: $green;
      opacity: 1;
    }
  }

  @include media-layout("small") {
    top: 157px;
    right: 30px;
  }
}

.allow-notifications-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  #allow-notifications {
    margin-left: 20px;
    margin-right: 30px;
  }
}
