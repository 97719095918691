@import "_mixins.scss";

.error-container {
  @include flex-full-centered-items;
  @include border-radius(50%);
  width: 346px;
  height: 346px;
  background: $black;
  border: 66px solid #e5eff9;
  color: #fff;

  .error-content {
    @include flex-centered-column;
    width: 210px;

    &.err500 {
      width: 246px;
    }

    font-family: "Lato Bold";
    h1 {
      font-family: "Lato Light";
      font-size: 12rem;
      font-weight: 200;
      margin: 60px 0 18px 0;
      line-height: 8rem;
    }

    h2 {
      font-family: "Lato Bold";
      font-size: 1.6rem;
      margin: 0 0 8px 0;
    }

    h3 {
      opacity: 0.8;
      font-size: 1.4rem;
      margin: 0 0 4px 0;
    }

    p {
      &.error_desc {
        font-family: "Lato Italic";
        font-size: 1.6rem;
        text-align: center;
        opacity: 0.6;
        margin: 0 0 20px 0;
      }
    }

    a {
      font-family: "Lato Italic";
      font-size: 1.4rem;
      opacity: 0.6;
      color: #fff;
      text-decoration: none;
    }
  }
}
