@import "_mixins";
@import "variables";

/* Customize the label (the container) */
.container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Lato Regular";
  font-size: 1.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  @include border-radius(6px);
  height: 25px;
  width: 25px;
  background-color: #fff;
  margin-right: 17px;
  border: 1px solid rgba($blue, 0.3);
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  border-color: $blue;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:disabled ~ .checkmark {
  background-color: $light-gray;
  cursor: not-allowed;
}

.container input:disabled ~ .checkmark:after {
  opacity: 0.7;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid $green;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-error .checkmark {
  border: 1px solid $red;
}

.checkbox-container {
  flex-wrap: wrap;
  width: 409px;
}

.checkbox-inline-error {
  display: block;
  font-family: "Lato Italic";
  font-size: 1.5rem;
  color: $red;
  margin-top: 10px;
  flex: 0 0 100%;
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 45px;
  min-height: 30px;
  margin-bottom: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid rgba($blue, 0.5);
  }

  .label {
    font-family: "Lato Medium";
    font-size: 1.4rem;
  }
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-container:hover input ~ .checkmark {
  border-color: $blue;
}

.radio-container:not(:hover) input:checked ~ .checkmark {
  border-color: rgba($blue, 0.5);
}

.radio-container:hover input ~ .checkmark:after,
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container:hover input ~ .checkmark:after,
.radio-container .checkmark:after {
  top: 6px;
  left: 6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $green;
}
