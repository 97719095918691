.numberrangeinput {
  display: flex;

  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    accent-color: $yellow;
    cursor: pointer;
    width: 240px !important;

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      background-color: $yellow;
      height: 12px;
      border-radius: 20px;
      outline: none;
      border: 4px solid $black;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      margin-top: -1.3rem;
      background-image: url("../images/ico_slider.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 3rem 3rem;
      height: 3rem;
      width: 3rem;
      border-radius: 3rem;
    }

    &::-moz-range-track {
      -moz-appearance: none;
      background: $black;
      height: 10px;
      border-radius: 20px;
    }

    &::-moz-range-thumb {
      -moz-appearance: none;
      background-image: url("../images/ico_slider.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 3rem 3rem;
      height: 3rem;
      width: 3rem;
      border-radius: 3rem;
    }

    &::-moz-range-progress {
      -moz-appearance: none;
      background-color: $yellow;
      border-radius: 2rem;
      border: 4px solid $black;
    }
  }
}
