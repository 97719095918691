@import "variables";

.gantt {
  .bar {
    fill: rgba(243, 214, 66, 1);
  }

  .trip {
    .bar {
      fill: rgba(54, 220, 138, 1);
    }
    .bar-label {
      fill: $white;
    }
  }

  .upper-text {
    font-family: "Lato Black";
    font-size: 1.8rem;
  }

  .lower-text {
    font-family: "Lato Regular";
    font-size: 1.8rem;
  }

  .lower-text-holiday {
    fill: $red;
  }

  .lower-text-saturday {
    fill: rgba(0, 0, 0, 0.4);
  }

  .holiday-highlight {
    fill: $red;
    opacity: 0.02;
  }

  .saturday-highlight {
    fill: rgba(0, 0, 0, 0.01);
  }

  .today-highlight {
    fill: rgba(223, 239, 255, 1);
  }

  .grid-header {
    fill: $light-gray;
    stroke: $light-gray;
  }

  .grid-row {
    fill: rgba(252, 252, 252, 1);
    &:nth-child(even) {
      fill: rgba(250, 250, 250, 1);
    }
  }

  .tick {
    stroke: $border-color;
    stroke-width: 2px;
    &.thick {
      stroke: rgba(0, 0, 0, 0.2);
      stroke-width: 3px;
    }
  }

  .bar-label {
    font-family: "Lato Regular";
    font-size: 1.6rem;
    fill: $black;
  }

  .bar-wrapper {
    cursor: default;
    &[data-id="0"] {
      &.trip {
        .bar {
          fill: rgba(59, 183, 122, 1);
        }
        .bar-label {
          fill: $white;
        }
      }
      &:hover {
        &.trip {
          .bar {
            fill: rgba(59, 183, 122, 1);
          }
          .bar-label {
            fill: $white;
          }
        }
      }
    }
    &:hover {
      .bar {
        fill: rgba(243, 214, 66, 1);
      }
      &.trip {
        .bar {
          fill: rgba(54, 220, 138, 1);
        }
      }

      .handle {
        visibility: hidden;
      }
    }
  }
}

.gantt-wrapper {
  width: 64%;
  display: inline-block;
  vertical-align: top;
  padding-top: 35px;
}

#driversSchedule {
  width: 35%;
  display: inline-block;
  vertical-align: top;
}

.assignment-container {
  display: flex;
  margin: 20px 0;
  justify-content: flex-end;
  #cancelButton {
    height: 40px;
    display: inline;
  }

  #assignmentConfirmationButton {
    height: 40px;
    display: inline;
  }
}

#schedule-card {
  overflow: auto;
  max-height: 80vh;
  white-space: nowrap;
}

@media screen and (max-height: 1170px) {
  #schedule-card {
    max-height: 75vh;
  }
}

@media screen and (max-height: 1000px) {
  #schedule-card {
    max-height: 65vh;
  }
}

.legend {
  position: relative;
  .legend-container {
    padding-top: 11px;
    width: 1900px;
    margin-right: 30px;
    height: 35px;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 4px;
  }
  .absence {
    position: absolute;
    left: 25px;
    display: flex;
    .yellow-box {
      height: 26px;
      width: 60px;
      background-image: url("../images/yellow.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    label {
      margin: 2px 0 0 20px;
      font-family: "Lato Italic";
      font-size: 1.7rem;
      font-weight: 900;
    }
  }
  .busy {
    position: absolute;
    left: 280px;
    display: flex;
    .green-box {
      height: 26px;
      width: 60px;
      background-image: url("../images/green.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    label {
      margin: 2px 0 0 20px;
      font-family: "Lato Italic";
      font-size: 1.7rem;
      font-weight: 900;
    }
  }
}

.card-body-width {
  min-width: 670px;
}
