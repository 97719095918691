@import "variables";
@import "tabulator-tables";

.tabulator {
  border: none;
  background-color: $light-gray;
  .tabulator-header {
    font-family: "Lato Black";
    font-size: 2.7rem;
    text-align: left;
    color: $black;
    background-color: $light-gray;
    border: none;
    .tabulator-col {
      border: none;
      background-color: $light-gray;
      font-size: 1.8rem;
      font-weight: normal;
      padding: 1rem 0;
      display: inline-flex;
      flex-direction: column;

      &.checkbox-cell {
        border: none;
        .tabulator-col-content {
          margin: auto 0 0 0;

          input {
            width: 32px;
            height: 32px;
            margin: 0 auto;
          }
        }
      }

      .tabulator-col-content {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        margin-top: auto;

        &:has(.tabulator-header-filter) {
          height: 100%;
        }

        &.already_filtered {
          border-top: solid $blue 2px;
          height: calc(100% - 2px);
          .noUi-connect {
            background-color: $blue !important;
          }
        }

        .table-header-menu {
          padding: 1rem;
          border-radius: 4px;
          background-color: $black;
          color: #ffffff;
          z-index: auto;
          position: relative;
          margin: 10px;

          &:hover {
            background-color: $blue;
            &.filtered_or_modified {
              &:after {
                background: transparent
                  url("../images/tables/menu-indicator-hover.svg") no-repeat
                  right center;
              }
            }
          }
          &.filtered_or_modified {
            &:after {
              @include position-absolute($top: 5px, $right: 5px);
              z-index: 10;
              content: "";
              top: -10px;
              right: -16px;
              width: 30px;
              height: calc(100% - 10px);
              background: transparent url("../images/tables/menu-indicator.svg")
                no-repeat right center;
            }
          }
        }

        .tabulator-col-sorter {
          .tabulator-arrow {
            border: none !important;
            height: 6px;
            margin: -9px -5px -5px -10px;
            padding: 5px 10px 5px 10px;
          }
        }
        .tabulator-col-title-holder {
          padding: 0 1.5rem;
          .tabulator-col-title {
            input {
              width: 24px;
              height: 24px;
            }
          }
          .tabulator-header-popup-button {
            opacity: 0;
          }
        }
        .tabulator-header-filter {
          margin-bottom: auto;
          input {
            color: $black;
            background: $white;
            border: 1px solid #e1dfdf;
            border-radius: 6px;
            padding: 0.75rem 1rem !important;

            &:hover {
              border: 1px solid $blue;
            }

            &:focus-visible {
              outline: none;
              border: 1px solid $blue;
            }
          }

          .daterangeinput,
          .timeinput,
          .dateinput {
            max-width: 80%;
          }

          .numberrangeinput {
            font-family: "Lato Regular";
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .noUi-target {
              margin-bottom: 0.5rem;
              margin-top: 0.4rem;
              margin-left: 15px;
              margin-right: 15px;
              height: 10px;
              .noUi-connects {
                border-radius: 0;
                .noUi-connect {
                  background-color: $green;
                }
              }
              .noUi-origin {
                &:last-of-type {
                  .noUi-handle {
                    @include position-absolute($top: -7px, $right: -19px);
                    background: transparent
                      url("../images/tables/slider-button-r.svg") no-repeat
                      right center;
                    &:hover {
                      background: transparent
                        url("../images/tables/slider-button-r-hover.svg")
                        no-repeat right center;
                    }
                  }
                }

                .noUi-handle {
                  @include position-absolute($top: -7px, $right: -5px);
                  width: 22px;
                  height: 23px;
                  border-radius: 10px;
                  border: none;
                  box-shadow: none;
                  background: transparent
                    url("../images/tables/slider-button-l.svg") no-repeat right
                    center;
                  &:hover {
                    background: transparent
                      url("../images/tables/slider-button-l-hover.svg")
                      no-repeat right center;
                  }
                  &:before {
                    display: none;
                  }
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      &:hover {
        .tabulator-col-content {
          .tabulator-header-popup-button {
            opacity: 1;
          }
        }
      }

      &.tabulator-sortable {
        &:hover {
          background: rgba($green, 0.33) !important;
        }
      }
      &.tabulator-sortable[aria-sort="none"] {
        &:hover {
          .tabulator-arrow {
            background: no-repeat url(../images/tables/st-up.svg);
            background-size: 100% 100%;
          }
        }
      }
      &.tabulator-sortable[aria-sort="ascending"] {
        .tabulator-col-content {
          .tabulator-col-sorter {
            .tabulator-arrow {
              background: no-repeat url(../images/tables/st-up.svg);
              background-size: 100% 100%;
            }
          }
        }
      }
      &.tabulator-sortable[aria-sort="descending"] {
        .tabulator-col-content {
          .tabulator-col-sorter {
            .tabulator-arrow {
              background: no-repeat url(../images/tables/st-down.svg);
              background-size: 100% 100%;
            }
          }
        }
      }
      &.tabulator-moving {
        border: solid 2px #0164c7;
        background: $light-blue;
      }

      &.tabulator-frozen {
        .tabulator-col-content {
          @include flex-full-centered-items;
          height: 100%;
          margin: 0;
          .tabulator-header-popup-button {
            opacity: 1;
          }
        }
      }

      &.tabulator-frozen-right {
        border: none;
      }
    }
  }

  .tabulator-tableholder {
    .tabulator-table {
      background-color: $light-gray;
      .tabulator-row {
        border: 2px solid transparent;
        border-bottom: 2px solid #f4f4f4;

        &:nth-child(even) {
          background: #fafafa;
        }
        &:nth-child(odd) {
          background: #fcfcfc;
        }

        &.tabulator-selected {
          background-color: $light-blue;
        }

        .tabulator-cell {
          border: none;
          font-family: "Lato Regular";
          font-size: 1.7rem;
          padding: 1rem 2rem;
          input {
            width: 24px;
            height: 24px;
          }
          a {
            &:visited {
              color: black;
            }
          }

          .cell-list {
            .cell-list-element {
              display: block;
              &:not(:first-child) {
                margin-top: 9px;
              }
            }
          }

          .status {
            width: fit-content;
            font-size: 1.3rem;
            padding: 0.5rem 1rem;
            color: white;
            background-color: $green;
            border-radius: 11px;
            margin-top: 9px;
          }

          .btn-table-full {
            font-size: 1.5rem;
          }
        }
        .checkbox-cell {
          background-color: inherit;
        }
      }
      .tabulator-selectable {
        &:hover {
          background: $light-blue !important;
          outline: solid 2px $blue;
          outline-offset: -2px;
        }
      }
      .error-row {
        background: rgba($red, 0.1) !important;
        outline: solid 2px $red;
        outline-offset: -2px;
        .tabulator-cell {
          background-color: unset;
        }

        &:hover {
          background: rgba($red, 0.2) !important;
          outline: solid 3px $red;
          outline-offset: -3px;
        }
      }
      .unread-notification {
        background: $light-blue !important;
        cursor: pointer;
        .tabulator-cell {
          font-family: "Lato Bold";
        }
      }
    }
    .tabulator-placeholder {
      .tabulator-placeholder-contents {
        font-family: "Lato Regular";
        position: absolute;
        left: 0;
        font-size: 3rem;
        color: rgba($black, 0.7);
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          margin: 1rem 0;
          max-width: 200px;
        }
      }
    }
  }

  .tabulator-col-resize-handle {
    width: 3px;
    margin: 0 4px;
    &:hover {
      background-color: rgba($blue, 0.5);
    }
  }

  &.reversed-select {
    .tabulator-tableholder {
      .tabulator-table {
        .tabulator-row {
          background-color: $light-blue;
          &.tabulator-selected {
            &:nth-child(even) {
              background: #fafafa;
            }
            &:nth-child(odd) {
              background: #fcfcfc;
            }
          }
        }
      }
    }
  }

  &.from-top-tabulator {
    .tabulator-cell {
      vertical-align: top;
    }
  }
  &:not(.from-top-tabulator) {
    .tabulator-cell {
      height: auto !important;
    }
  }

  .tabulator-alert {
    background-color: rgba(255, 255, 255, 0.9);
    .tabulator-alert-msg.tabulator-alert-state-msg {
      font-size: 3rem;
      padding: 3.5rem 5.75rem;
      font-family: "Lato Bold";
      color: $blue;
      border: 30px solid #e5eff963;
      border-radius: 0;
      background-color: #ffffff;
    }
  }
}

.tabulator-frozen-right {
  max-width: 250px;
  box-shadow: 0 0 10px 0px rgba($green, 0.1);
  clip-path: inset(0px 0px 0px -10px);
}

.checkbox-cell {
  input {
    appearance: none;
    margin: 0;
    font: inherit;
    color: $blue;
    width: 25px;
    height: 25px;
    border: 2px solid #0164c74d;
    border-radius: 6px;
    display: grid;
    place-content: center;
    cursor: pointer;

    &:hover {
      border: 2px solid $blue;
    }

    &:before {
      content: "";
      width: 13px;
      height: 13px;
      transform: scale(0);
      transition: 80ms transform ease-in-out;
      box-shadow: inset 13px 13px $green;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked {
      border: 2px solid $blue;

      &:before {
        transform: scale(1);
      }
    }

    &:indeterminate {
      &:before {
        box-shadow: inset 13px 13px $blue;
        clip-path: polygon(100% 35%, 100% 60%, 0% 60%, 0% 35%);
        transform: scale(1);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
      background-color: $light-gray;
    }
  }

  &.small {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    vertical-align: center;
    border-radius: 3px;
    input {
      width: 15px;
      height: 15px;

      &:before {
        width: 7px;
        height: 7px;
      }
    }
  }
}

.selected-counter {
  margin-right: auto;
  border: 1px solid $white;
  border-radius: 4px;
  padding: 1rem 2rem;
  color: rgba($black, 0.7);
  font-family: "Lato Black Italic";
  font-size: 1.6rem;
  display: inline-flex;

  .info {
    font-family: "Lato Italic";
  }

  .counter-element:not(:last-of-type) {
    margin-right: 20px;
  }

  &.routes {
    display: block;
  }
}

.tabulator-popup-container {
  box-shadow: 0px 3px 6px #267f5333;
  border: 1px solid #f4f4f4;
  border-radius: 6px;
  padding: 1rem 0.5rem;
  min-width: 180px;
  max-height: 50vh;
  overflow-y: auto;
  .tabulator-menu-item,
  .tabulator-edit-list-item {
    color: $black;
    padding: 0.75rem 1rem;
    font-family: "Lato Regular";

    &.active {
      font-family: "Lato Bold";
      background: #ffffff;
      color: $black;
    }

    &.focused,
    &:hover {
      background: $light-blue !important;
      color: $black;
      outline: none;
    }
  }

  .tabulator-menu-separator {
    border-top: 3px solid #f4f4f4;
    margin: 4px 0;
  }
}

.tabulator-tabs {
  display: flex;
  border-bottom: 7px solid #267f531a;
  margin: -2rem -2rem 0 -2rem;
  .tab-button {
    font-family: "Lato Regular";
    font-size: 1.8rem;
    padding: 3rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 300px;
    text-align: start;
    &.active {
      font-family: "Lato Bold";
      background-color: #267f531a;
    }

    &:hover {
      background-color: #ffffff;
    }
  }
}

.table-label {
  text-align: left;
  font-family: "Lato Bold";
  font-size: 1.6rem;
  letter-spacing: 0px;
  color: $black;
}

.tabulator-row .tabulator-cell.tabulator-editing input,
.custom-editable {
  color: $black;
  background: $white;
  border: 1px solid #e1dfdf;
  border-radius: 6px;
  padding: 0.75rem 1rem !important;
  width: 90%;
  text-overflow: ellipsis;

  &:hover {
    border: 1px solid $blue;
  }

  &:focus-visible {
    outline: none;
    border: 1px solid $blue;
  }
}

.tabulator-cell.edit-input {
  text-overflow: unset;
}
