$green: #267f53;
$black: #1d1d1b;
$gray: #707070;
$red: #f10039;
$blue: #0164c7;
$yellow: #f3d642;
$light-yellow: #f6ff7a2e;
$blue_second: #3276b1;
$light-blue: #dfefff;
$white: #f8f8f8;
$light-gray: #f1f1f1;
$shadow-color: #00000029;
$error_bg: #fbe5e5;
$border-color: #f1f2f4;
$form-background-color: #f7fafd;
$detail-bg-color: #f8f8f8;
$light-green: #267f5349;
$border-gray: #f1f2f4ff;
$light-blue: #e5eff9;
$light-gray: #f4f4f4;
$mine-shaft: #292929;
$mercury: #e5e5e5;
$mercury-dark: #e2e2e2;
$mariner: #3270d3;
$cod-gray: #f0f0f0;

$breakpoints: (
  "mobile": 420px,
  "small": 768px,
  "form_breakpoint": 900px,
);
