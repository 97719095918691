@import "variables";

@mixin flex-centered-items {
  display: flex;
  align-items: center;
}

@mixin flex-align-right-items {
  @include flex-centered-items;
  justify-content: flex-end;
}

@mixin flex-inline-centered {
  display: inline-flex;
  align-items: center;
}

@mixin flex-inline-full-centered {
  @include flex-inline-centered;
  justify-content: center;
}

@mixin flex-simple-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-start-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-inline-column {
  display: inline-flex;
  flex-direction: column;
}

@mixin flex-stretched-items {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

@mixin flex-centered-column {
  @include flex-centered-items;
  flex-direction: column;
}

@mixin flex-space-between-column {
  @include flex-centered-column;
  justify-content: space-between;
}

@mixin flex-full-centered-items {
  @include flex-centered-items;
  justify-content: center;
}

@mixin flex-space-between {
  @include flex-centered-items;
  justify-content: space-between;
}

@mixin flex-space-around {
  @include flex-centered-items;
  justify-content: space-around;
}

@mixin flex-column-space-around {
  @include flex-simple-column;
  justify-content: space-around;
}

@mixin flex-inline {
  @include flex-centered-items;
  flex-direction: row;
}

@mixin border-radius(
  $value: 0,
  $left-top: "",
  $right-top: "",
  $right-bottom: "",
  $left-bottom: ""
) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
  @if $left-top {
    -webkit-border-top-left-radius: $left-top;
    -moz-border-top-left-radius: $left-top;
    border-top-left-radius: $left-top;
  }
  @if $left-bottom {
    -webkit-border-bottom-left-radius: $left-bottom;
    -moz-border-bottom-left-radius: $left-bottom;
    border-bottom-left-radius: $left-bottom;
  }
  @if $right-top {
    -webkit-border-top-right-radius: $right-top;
    -moz-border-top-right-radius: $right-top;
    border-top-right-radius: $right-top;
  }
  @if $right-bottom {
    -webkit-border-bottom-right-radius: $right-bottom;
    -moz-border-bottom-right-radius: $right-bottom;
    border-bottom-right-radius: $right-bottom;
  }
}

@mixin position-absolute(
  $top: initial,
  $right: initial,
  $bottom: initial,
  $left: initial
) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin button-outline($color: $black, $color_outline: #fff) {
  background-color: transparent;
  color: $color;
  border: 2px solid $color;

  &:hover,
  &:focus {
    background-color: $color;
    color: $color_outline;
  }
}

@for $i from 1 to 10 {
  .margin-right-#{$i * 10} {
    margin-right: #{$i * 10}px !important;
  }
  .margin-bottom-#{$i * 10} {
    margin-bottom: #{$i * 10}px !important;
  }
  .margin-top-#{$i * 10} {
    margin-top: #{$i * 10}px !important;
  }
  .margin-left-#{$i * 10} {
    margin-left: #{$i * 10}px !important;
  }
}

@mixin media-layout($breakpoint, $condition: "max-width", $extra_query: null) {
  @if not map-get($breakpoints, $breakpoint) {
    @if $extra_query {
      @media all and (#{$condition}: $breakpoint), (#{$extra_query}) {
        @content;
      }
    } @else {
      @media all and (#{$condition}: $breakpoint) {
        @content;
      }
    }
  } @else {
    @if $extra_query {
      @media all and (#{$condition}: map-get($breakpoints, $breakpoint)), (#{$extra_query}) {
        @content;
      }
    } @else {
      @media all and (#{$condition}: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }
  }
}

@for $i from 0 to 10 {
  $i_2: $i * 10 + 5;

  .gap-#{$i * 10} {
    gap: #{$i * 10}px;
  }
  .gap-#{$i_2} {
    gap: #{$i_2}px;
  }

  @include media-layout("small") {
    .gap-s-#{$i * 10} {
      gap: #{$i * 10}px !important;
    }
    .gap-s-#{$i_2} {
      gap: #{$i_2}px !important;
    }
  }
}

@for $i from 0 to 15 {
  .mr-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        margin-right: #{$i * 10}px !important;
      }
    }
  }
  .mb-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        margin-bottom: #{$i * 10}px !important;
      }
    }
  }
  .mt-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        margin-top: #{$i * 10}px !important;
      }
    }
  }
  .ml-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        margin-left: #{$i * 10}px !important;
      }
    }
  }
  .p-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        padding: #{$i * 10}px !important;
      }
    }
  }
  .pv-#{$i * 10} {
    padding-top: #{$i * 10}px !important;
    padding-bottom: #{$i * 10}px !important;
  }
  .pv-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        padding-top: #{$i * 10}px !important;
        padding-bottom: #{$i * 10}px !important;
      }
    }
  }
  .ph-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        padding-left: #{$i * 10}px !important;
        padding-right: #{$i * 10}px !important;
      }
    }
  }
  .pr-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        padding-right: #{$i * 10}px !important;
      }
    }
  }
  .pb-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        padding-bottom: #{$i * 10}px !important;
      }
    }
  }
  .pt-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        padding-top: #{$i * 10}px !important;
      }
    }
  }
  .pl-s-#{$i * 10} {
    @include media-layout("small") {
      & {
        padding-left: #{$i * 10}px !important;
      }
    }
  }
}
