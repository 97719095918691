.swal2-container {
  .swal2-popup {
    font-family: "Lato Regular";

    .swal2-title {
      font-family: "Lato Black";
      font-weight: normal;
      font-size: 3rem;
      color: $green;
      text-align: left;
      padding-top: 0;
    }

    .swal2-html-container {
      font-family: "Lato Bold";
      text-align: left;
      font-size: 2rem;
      background-color: #f8f8f8;
      color: #000000;
      padding: 1.5rem;
      margin: 3.4rem 3rem 0 3rem;

      small {
        font-family: "Lato Italic";
        font-size: 1.6rem;
        display: block;
        margin-top: 1rem;
      }
    }

    .swal2-select {
      @extend %input-accounts-style;
      width: auto;
      font-size: 1.4rem;
    }

    .swal2-input {
      @extend %input-accounts-style;
      width: auto;
    }

    .swal2-textarea {
      @extend %input-accounts-style;
      width: auto;
      height: 6.75em !important;
      resize: none;
    }

    .swal2-input-label {
      font-family: "Lato Bold";
      font-size: 1.6rem;
      text-align: left;
      margin: 1rem auto 0 3rem;
      justify-content: flex-start;
    }

    .swal2-actions {
      justify-content: flex-end;
      margin: 3rem 3rem 0 auto;
    }
  }
  .choices__list.choices__list--dropdown.is-active {
    z-index: 999;
  }
}
