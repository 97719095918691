@import "variables";

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  background-color: rgb(184, 211, 198, 0.8);

  &.show {
    opacity: 1;
    animation-name: animatebackdrop;
    animation-duration: 0.3s;
  }

  &.hide {
    opacity: 0;
    animation-name: animatebackdrop-reversed;
    animation-duration: 0.3s;
  }

  .modal__content {
    background-color: #ffffff;
    margin: auto;
    padding: 0;
    border-radius: 26px;
    max-width: 80%;
    box-shadow: 0px 30px 30px #267f534a;
    display: inline-block;

    .modal__header {
      position: relative;
      padding: 0 16rem;
      background-color: #ffffff;
      border-radius: 26px 26px 0 0;
      display: flex;

      h2 {
        font-family: "Lato Black";
        font-weight: normal;
        font-size: 3rem;
        color: $green;
        margin: 3rem auto 2rem 0;
      }

      .modal__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        color: $black;
        float: right;
        font-size: 3rem;
        font-weight: bold;

        &:hover,
        &:focus {
          color: $gray;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    .modal__body {
      padding: 0 16rem;

      form {
        margin-top: 0px;
        max-width: 800px;
        .form-container {
          padding: 0;
          border: none;
          .form-main-content {
            padding: 0;
          }
        }
      }
      ol {
        font-size: 1.8rem;
        li {
          font-family: "Lato Bold";
          color: $black;
        }
        .point_data {
          margin-left: -20px;
          margin-bottom: 30px;
          display: flex;
          flex-wrap: wrap;

          a {
            font-family: "Lato Regular";
            margin-top: 12px;
            color: rgba($black, 0.6);
            &:not(:last-of-type) {
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: calc(33.33% - 20px);
            }
          }
        }
      }

      .simple-table {
        background-color: #f4f4f4;
        font-family: "Lato Regular";
        font-size: 1.6rem;
        margin-bottom: 3rem;
        border-collapse: collapse;
        border: 3px solid #f4f4f4;
        text-align: left;

        thead {
          font-family: "Lato Bold";
          font-size: 1.8rem;
        }

        tbody {
          tr:nth-child(odd) {
            background-color: rgba(255, 255, 255, 0.6);
          }
          tr:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.9);
          }

          tr:not(:last-child) {
            border-bottom: 3px solid #f4f4f4;
          }
        }

        td,
        th {
          padding: 1.5rem 2rem;
          border-right: none;
          border-left: none;
        }
      }

      .modal-image {
        width: 100%;
        max-height: 75vh;
        object-fit: contain;
        margin-bottom: 3rem;
      }

      .preview-comment {
        display: flex;
        flex-direction: column;
        padding: 15px 13px;
        background: #f8f8f8;
        font-family: "Lato Italic";
        font-size: 16px;
        -o-object-fit: contain;
        object-fit: contain;
        margin-bottom: 3rem;
        width: 400px;
        margin-left: auto;
        overflow-wrap: break-word;

        span {
          font-family: "Lato Bold";
          font-size: 20px;
        }
      }

      .modal-buttons {
        display: flex;
        justify-content: right;
        align-items: center;
      }
    }

    .modal__footer {
      padding: 2px 16px;
      background-color: $light-gray;
      color: $black;
    }
  }
}

@keyframes animatebackdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animatebackdrop-reversed {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.driver-dashboard-modal {
  color: #267f53;
  font-weight: bold;
  position: absolute;
  left: 20px;
}
