$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-max-multi-column: unquote('"\\e907"');
$icon-address: unquote('"\\e900"');
$icon-weight: unquote('"\\e901"');
$icon-resize-length: unquote('"\\e902"');
$icon-thermometer: unquote('"\\e903"');
$icon-clock: unquote('"\\e904"');
$icon-multi-column: unquote('"\\e905"');
$icon-single-column: unquote('"\\e906"');

@font-face {
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: normal;
  src:
    local("Lato-Regular"),
    url("../fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Bold";
  font-style: normal;
  font-weight: normal;
  src:
    local("Lato-Bold"),
    url("../fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Black";
  font-style: normal;
  font-weight: normal;
  src:
    local("Lato-Black"),
    url("../fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Black Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("Lato-BlackItalic"),
    url("../fonts/Lato-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Light";
  font-style: normal;
  font-weight: normal;
  src:
    local("Lato-Light"),
    url("../fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("Lato-Italic"),
    url("../fonts/Lato-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Medium";
  font-style: normal;
  font-weight: normal;
  src:
    local("Lato-Medium"),
    url("../fonts/Lato-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?14f1zx");
  src:
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?14f1zx#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?14f1zx")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?14f1zx")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?14f1zx##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-max-multi-column {
  &:before {
    content: $icon-max-multi-column;
    color: #0164c7;
  }
}
.icon-address {
  &:before {
    content: $icon-address;
    color: #267f53;
  }
}
.icon-weight {
  &:before {
    content: $icon-weight;
    color: #267f53;
  }
}
.icon-resize-length {
  &:before {
    content: $icon-resize-length;
    color: #267f53;
  }
}
.icon-thermometer {
  &:before {
    content: $icon-thermometer;
    color: #267f53;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
    color: #267f53;
  }
}
.icon-multi-column {
  &:before {
    content: $icon-multi-column;
    color: #0164c7;
  }
}
.icon-single-column {
  &:before {
    content: $icon-single-column;
    color: #0164c7;
  }
}
