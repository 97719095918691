.progressBarWrapper {
  padding: 1px;
  width: 100%;
  display: block;
  margin: 0;
  position: relative;
  background: #e5eff9;
  border-radius: 12px;

  .progressBar {
    height: 6px;
    display: block;
    width: 0%;
    background: repeating-linear-gradient(to right, $blue, #b0ceec, $blue);
    background-size: 200% auto;
    background-position: 0 100%;
    position: relative;
    overflow: hidden;
    transition: all 700ms ease;
    border-radius: 12px;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}
