@import "variables";

.fileupload-container {
  width: 409px;
  .fileupload-label {
    width: calc(100% - 2rem);
    border: 3px dashed $blue;
    border-radius: 6px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .fileupload-dropzone {
      width: 100%;
      padding: 4rem 0 2.5rem 0;
      display: flex;
      gap: 1.25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: $form-background-color;
      cursor: pointer;
      transition:
        background 0.2s ease-in-out,
        border 0.2s ease-in-out;

      .fileupload-title {
        color: $black;
        font-size: 2rem;
        font-family: "Lato Regular";
        text-align: center;
        transition: color 0.2s ease-in-out;
      }

      .fileupload-or {
        color: $black;
        font-size: 1.4rem;
        font-family: "Lato Italic";
        text-align: center;
        transition: color 0.2s ease-in-out;
      }
    }

    .fileupload {
      display: none;
    }

    .fileupload-button-container {
      background-color: $form-background-color;
      width: 100%;
      display: flex;
      border-top: 1px solid #ffffff;
      border-radius: 4px;
      .fileupload-select {
        margin: 1rem auto;
        width: 236px;
      }
    }

    &:hover,
    &.dragover {
      cursor: pointer;
      .fileupload-dropzone {
        background: $blue;
      }

      .fileupload-title,
      .fileupload-or {
        color: #ffffff;
      }

      .fileupload-button-container {
        background-color: $light-blue;
        transition: background-color 0.2s ease-in-out;
      }
    }
  }

  select {
    display: none;
  }

  .fileupload-list {
    .file {
      width: 409px;
      padding: 1.5rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      &:not(:last-child) {
        border-bottom: 3px solid $light-blue;
      }

      .thumbnail-frame {
        cursor: pointer;

        &:hover {
          outline: 2px solid #0164c7;
          background-color: rgba(1, 100, 199, 0.1490196078);
        }
      }

      .thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0164c70d;
        border: 1px solid #e1dfdf;
        height: 64px;
        width: 64px;
        flex-shrink: 0;
        img {
          max-height: 64px;
          max-width: 64px;
          object-fit: cover;

          &.hidden {
            display: none;
          }
        }

        i.fa {
          font-size: 4rem;
          color: rgba($blue, 0.1);
          &.hidden {
            display: none;
          }
        }
      }

      .file-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        font-size: 1.75rem;
        cursor: pointer;
        height: 63px;
        justify-content: center;
        word-break: break-all;

        &:hover {
          background-color: #eaf2fa;
        }
      }

      .file-delete {
        color: $blue;
        background-color: $light-blue;
        border-radius: 50%;
        border: none;
        font-size: 2.5rem;
        width: 3.5rem;
        height: 3.5rem;
        flex-shrink: 0;

        .fa-times {
          pointer-events: none;
        }

        &:hover {
          cursor: pointer;
          color: #ffffff;
          background-color: $black;
        }
      }
    }
  }
}

.file-gallery {
  .files {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
    grid-gap: 2rem;
    margin-bottom: 2rem;
    .file {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #0164c70d;
      border: 1px solid #e1dfdf;
      height: 64px;
      width: 64px;
      flex-shrink: 0;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        outline: 2px solid $blue;
        background-color: #0164c726;
      }

      img {
        max-height: 64px;
        max-width: 64px;
        object-fit: cover;
        border-radius: 6px;
      }

      i.fa {
        font-size: 4rem;
        color: rgba($blue, 0.1);
      }
    }
  }

  .file-gallery-actions {
    display: flex;
    justify-content: flex-end;
    border-bottom: 3px solid $light-blue;
    padding-bottom: 2.5rem;
    margin-bottom: 2rem;
  }
}

.trip-files {
  .data-cell-content {
    margin-left: 8px;
  }
}
