@import "variables";
@import "mixins";

.asteriskField {
  @include position-absolute($top: -10px, $right: -15px);
  color: $blue;
  margin-left: 0.25rem;
  font-family: "Lato Black";
  font-size: 3rem;
}

.flex-row {
  width: 100%;
  display: flex;
  justify-content: space-between;

  fieldset {
    flex: 1;
  }
}

form {
  .controls {
    position: relative;

    input {
      width: 100%;

      &.checkboxinput {
        width: auto;
      }
    }

    select {
      width: 100%;
    }

    textarea {
      @extend %input-accounts-style;
      width: 100% !important;
      height: initial !important;
      resize: vertical;
    }
  }
}

%form {
  @include flex-simple-column;
  margin: 32px 0 0 0;

  label:not(.container):not(.fileupload-label) {
    @extend %basic-label-style;
  }

  input:not([type="checkbox"]):not([type="submit"]):not([type="file"]) {
    @extend %input-accounts-style;
  }

  .form-buttons-container {
    @include flex-align-right-items;
    margin-top: 23px;
  }

  .italic {
    font-family: "Lato Italic";
    font-size: 1.4rem;
  }
}

%basic-label-style {
  position: relative;
  display: inline-block;
  margin-bottom: 7px;
  font-family: "Lato Bold";
  font-size: 1.6rem;
  color: $black;
}

%input-accounts-style {
  @include border-radius(6px);
  height: 38px;
  max-width: 409px;
  flex-shrink: 1;
  border: 1px solid rgba($blue, 0.3);
  box-sizing: border-box;
  padding: 10px 15px;
  font-family: "Lato Medium";
  font-size: 1.4rem;

  &:focus {
    border: none;
    outline: 3px solid $blue;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $detail-bg-color;
    border-color: #e1dfdf;
  }

  &.timeinput {
    width: 205px;
  }
}

.standalone-input {
  @extend %input-accounts-style;
  margin-top: 32px;
  margin-bottom: 24px;
}

%select-accounts-style {
  @extend %input-accounts-style;
  outline: none;
  appearance: none;
  background-image: url(../images/tables/bott-lista.svg) !important;
  background-position: right 3px center !important;
  background-repeat: no-repeat !important;
  background-size: auto 50%;

  &:focus {
    padding: 8px 13px;
    background-image: url(../images/tables/bott-lista-selected.svg) !important;
  }
}

.checkbox-div {
  padding-left: 15px;
}

.form-container {
  @include border-radius(26px);
  display: flex;
  flex-direction: column;
  border: 1px solid $border-color;
  background: #fff;
  padding: 12px;
  box-sizing: border-box;
  position: relative;

  .form-content-toggle {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    border: none;
    background-color: transparent;
    color: $blue;
    cursor: pointer;
    padding: 1rem;
    border: 2px solid transparent;
    border-radius: 3px;
    width: 38px;

    &:hover {
      color: #000000;
      border: 2px solid $border-color;
    }
  }

  .form-content-toggle:not(.active) ~ .form-main-content {
    padding: 0 138px 0 138px;

    h2 {
      margin: 0;
    }
  }

  .form-main-content {
    width: 700px;
    max-width: 100%;
    flex-shrink: 1;
    box-sizing: border-box;
    padding: 50px 138px 60px 138px;

    p:not(.help-block) {
      font-family: "Lato Regular";
      font-size: 2rem;
      &.small {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }
      &:not(.clear-p) {
        padding: 25px 16px;
        color: $black;
        background: #f8f8f8;
      }
      a {
        font-family: "Lato Black";
        color: $blue;
      }
      strong {
        font-family: "Lato Bold";
      }
    }

    .form-actions {
      display: flex;
      gap: 2rem;
      justify-content: flex-end;
    }

    .copy-data {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 2.5rem;
      margin-bottom: 2rem;
    }

    .form-vertical-buttons-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      max-width: 250px;
      margin: auto;
      text-align: center;
    }

    .subform {
      margin-bottom: 40px;
    }
  }

  h1,
  h2 {
    font-family: "Lato Black";
    font-size: 3rem;
    font-weight: normal;
    color: $green;
    margin: 0;

    + span {
      font-family: "Lato Regular";
      font-size: 1.4rem;
      color: $black;

      a {
        font-family: "Lato Black";
        color: $blue;
        text-decoration: underline;
      }
    }
  }

  h2 {
    margin-bottom: 32px;
    max-width: 265px;
  }

  .help-block {
    @include border-radius(4px);
    position: relative;
    max-width: 409px;
    width: 100%;
    font-family: "Lato Italic";
    font-size: 1.4rem;
    color: $black;
    background: rgba($blue, 0.03);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 22px 58px 22px 30px;
    box-sizing: border-box;

    &:before {
      content: "i";
      @include position-absolute($top: 0, $left: 0);
      @include border-radius(4px);
      @include flex-full-centered-items;
      width: 20px;
      height: 20px;
      font-family: "Times New Roman";
      font-weight: bold;
      font-style: italic;
      color: #fff;
      background: $blue_second;
    }
  }

  form {
    @extend %form;
    @include flex-simple-column;
    margin: 32px 0 0 0;

    label:not(.container):not(.fileupload-label) {
      @extend %basic-label-style;
    }

    input:not([type="checkbox"]) {
      @extend %input-accounts-style;
    }

    select {
      @extend %select-accounts-style;
      background: white;
    }

    .form-buttons-container {
      @include flex-align-right-items;
      margin-top: 23px;
    }

    .italic {
      font-family: "Lato Italic";
      font-size: 1.4rem;
    }
  }

  .responsive-form {
    @include media-layout("form_breakpoint") {
      input:not([type="checkbox"]):not([type="submit"]):not([type="file"]) {
        width: 100%;
      }

      select {
        width: 100% !important;
      }

      .form-buttons-container {
        flex-wrap: wrap;
        row-gap: 20px;
      }
    }

    @include media-layout(610px) {
      .captcha-container {
        flex-wrap: wrap;
        margin-top: 0;
        justify-content: flex-start;

        & > input {
          order: 2;
          margin-top: 20px;
        }

        .captcha-main {
          flex-basis: 100%;
          order: 1;
          align-items: stretch;

          img {
            object-fit: contain;
            flex-grow: initial;
          }
        }
      }
    }

    @include media-layout("mobile") {
      .form-buttons-container {
        button {
          font-size: 1.6rem;
          padding: 0.75rem 1.5rem;
        }
      }
    }
  }

  &.local {
    background: rgba(255, 255, 255, 1);
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    h1 {
      font-family: "Lato Black";
      font-size: 1.6rem;
    }
  }

  &.hidden {
    display: none;
  }
}

.control-group {
  margin-bottom: 24px;

  .captcha-container {
    margin-top: -20px;
  }

  & + .form-buttons-container {
    margin-top: 0 !important;
  }
}

.choices {
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  font-size: 1.6rem;
  overflow: visible;

  .choices__inner {
    @extend %input-accounts-style;
    min-height: 38px !important;
    display: flex;
    align-items: center;
    padding: 0 !important;
    font-size: 1.6rem;
    background-color: #ffffff;

    .choices__list {
      padding: 0.5rem 0 0.5rem 1rem;

      &.choices__list--single {
        display: flex;

        .choices__item {
          display: flex;
          gap: 1rem;
          align-items: center;
          padding-right: 50px;

          .choices__selected__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .choices__edit {
            display: none;
            font-family: "Lato Bold";
            font-size: 1.7rem;
            color: $black;
            padding: 0.4rem 2.5rem;
            margin: 0 5rem 0 auto;
            background-color: rgba($blue, 0.1);
            border-radius: 30px;
            border: none;
            cursor: pointer;

            &:hover {
              background-color: $blue;
              color: #ffffff;
            }
          }

          & + .choices__placeholder {
            display: none;
          }
        }
      }
    }

    .choices__input {
      background-color: #ffffff;
    }

    &:hover {
      .choices__list {
        &.choices__list--single {
          .choices__item {
            width: 100%;

            .choices__edit {
              display: block;
            }
          }
        }
      }
    }
  }

  .choices__list--dropdown {
    margin-top: 1rem;
    border-radius: 6px;

    &.is-active {
      outline: 3px solid $blue;
      border: none;
    }

    .choices__list {
      .choices__item {
        &.is-highlighted {
        }
        &.has-no-results,
        &.has-no-choices {
          display: flex;
          button {
            font-family: "Lato Regular";
            font-size: 1.6rem;
            margin: auto;
            transition: none;
            padding: 0.5rem 3rem;
          }
        }
      }
    }
  }

  &[data-type*="select-one"] {
    max-width: 409px;

    &:after {
      content: "\f078";
      @include flex-full-centered-items;
      @include position-absolute($top: 3px, $right: 3px);
      @include border-radius(4px);
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      width: 32px;
      height: 32px;
      margin: 0 !important;
      background: $black;
      border: 0;
      color: #fff;
    }
    &[aria-expanded="true"]:after {
      content: "\f077";
    }
    select.choices__input {
      display: block !important;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &[data-type*="select-multiple"] {
    .choices__inner {
      height: auto;
      .choices__list {
        .choices__item {
          background-color: $green;
          border: 3px solid $light-gray;
          white-space: nowrap;

          .choices__button {
            border-left: none;
            margin: 0;
          }
        }
      }

      input.choices__input {
        border: none !important;

        &:focus {
          outline: none !important;
        }
      }
    }
  }

  &.is-disabled {
    opacity: 0.5;
    .choices__inner {
      background-color: $white;
      border: 1px solid #e1dfdf;
    }
  }

  &.is-open {
    .choices__inner {
      outline: 3px solid $blue;
      border: none;
    }
  }

  .choices__detail {
    font-family: "Lato Black Italic";
    background-color: $blue_second;
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    border: none;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
  }

  .choices__input {
    border: none;
    outline: none;
    border-radius: 6px 6px 0 0 !important;
  }
}

.errors-form-container {
  @include border-radius(10px);
  padding: 16px 28px;
  background: $error_bg;

  & + .form-main-content {
    padding-top: 30px;
  }

  b {
    font-family: "Lato Bold";
    font-size: 1.8rem;
    color: $black;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;

    li {
      font-family: "Lato Regular";
      font-size: 1.4rem;
      color: $black;
    }
  }
}

.multiform {
  @extend %form;

  .two-columns-form {
    > div {
      width: 718px;
    }
  }

  .centered-wrapper {
    margin-bottom: 30px !important;
  }
}

.two-columns-form {
  @include flex-simple-column;
  margin: 0 auto;

  &.responsive-container {
    > div {
      width: 718px;
      max-width: initial;

      @include media-layout(1100px) {
        max-width: 718px;
        width: 100%;

        .form-main-content {
          padding: 46px;
        }
      }
    }

    &.multiple-columns-form {
      > div {
        max-width: 342px;

        .form-container {
          box-shadow: none;
        }

        .form-main-content {
          padding: 4px 21px;
        }

        .data-two-column-layout.grid-style {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.spinner-container {
  @include flex-inline-column;
  justify-content: space-between;
  @include position-absolute($top: 0);
  height: 38px;

  .up,
  .down {
    @include flex-full-centered-items;
    width: 32px;
    height: 14px;
    font-size: 1.2rem;
    background: $black;
    color: #fff;
  }

  .up {
    @include position-absolute($top: 3px, $right: 3px);
    @include border-radius(0, 4px, 4px, 0, 0);
  }

  .down {
    @include position-absolute($bottom: 3px, $right: 3px);
    @include border-radius(0, 0, 0, 4px, 4px);
  }
}

.error {
  input {
    border: 0 !important;
    outline: 3px solid $red;
    background: $error_bg;
  }
  .help-inline {
    display: block;
    font-family: "Lato Italic";
    font-size: 1.5rem;
    color: $red;
    margin-top: 10px;
  }
  .choices + .help-inline {
    margin-top: -14px;
  }

  .choices .choices__inner {
    border: 1px solid $red;
    outline: 2px solid $red;
    background: $error_bg;
  }
}

.multiform-buttons-container {
  @include flex-space-between;
  margin: 0 auto;
  max-width: 709px;
  width: 100%;
  gap: 1rem;
}

.multiform-button-container {
  @include flex-align-right-items;
  margin: 0 auto;
  max-width: 709px;
  width: 100%;
  gap: 1rem;
}

.time-range-container {
  display: flex;
  flex-direction: column;
  width: 205px;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid $border-color;
  margin-bottom: 2.4rem;
  background-color: $detail-bg-color;

  .controls {
    width: 205px;

    .timeinput:disabled ~ .btn-date-clear {
      display: none;
    }
  }

  .checkbox-div {
    div {
      width: 30%;
    }
  }
}

.form-header {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  margin-bottom: 0.8rem;

  &.draft {
    justify-content: end;
  }

  &.hidden {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(241, 242, 244, 1);
    border-radius: 26px;
    min-width: 690px;
    padding: 20px 20px;
  }

  &.local {
    background: rgba(255, 255, 255, 1);
    border-top: 1px solid rgba(241, 242, 244, 1);
    border-left: 1px solid rgba(241, 242, 244, 1);
    border-right: 1px solid rgba(241, 242, 244, 1);
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
    min-width: 726px;
    padding: 0;
    margin-bottom: 0;
    height: 66px;
    align-items: center;
    justify-content: unset;

    h3 {
      font-family: "Lato Black";
      font-size: 30px;
      font-size: 3rem;
      font-weight: normal;
      color: #267f53;
    }

    i {
      color: #0164c7;

      &.fa-chevron-right {
        margin-right: 30px;
        font-size: 1.4rem;
      }
    }

    span {
      height: 36px;

      &.badge {
        max-height: 14px;
        margin-left: auto;
        margin-right: 35px;
      }
    }
  }

  h3 {
    font-family: "Lato Bold";
    font-size: 1.6rem;
    margin: 0;
  }

  span.badge {
    font-family: "Lato Black";
    font-size: 1.2rem;
    text-decoration: none;
    border-radius: 1.1rem;
    padding: 0.25rem 1.5rem;
    min-width: 140px;
    color: $white;

    &.required,
    &.not-accepted {
      color: $black;
      background-color: $yellow;
    }

    &.error {
      background-color: $red;
    }

    &.saving {
      background-color: $blue;
    }

    &.accepted {
      background-color: $green;
    }
  }
}

.floating-banner-spacer {
  height: 2rem;
}

.floating-save-banner {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  transition:
    height 0.3s ease,
    background-color 0.3s ease-in;
  max-height: 8rem;
  background-color: #ffffff;
  box-shadow: 8px -10px 30px #267f5333;

  &.shown {
    height: 8rem;
  }

  &.hidden {
    visibility: hidden;
  }

  &.bottom {
    padding-bottom: 3rem;
    background-color: transparent;
    box-shadow: none;
  }

  &.sidebar-margin {
    .banner-contents {
      padding-left: 27rem;
    }
  }

  .banner-contents {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 726px;
    transition: padding-left 0.1s ease;

    .form-info {
      color: rgba($black, 0.7);
      font-family: "Lato Italic";
      font-size: 1.4rem;

      b {
        font-family: "Lato Black Italic";
      }
    }
    .form-actions {
      margin-left: 2rem;
    }
  }
}

.form-details {
  padding: 25px 16px;
  max-width: 376px;
  color: $black;
  background-color: $white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem 2rem;

  .info-detail {
    i {
      display: block;
      font-family: "Lato Medium";
      font-size: 1.2rem;
      text-decoration: none;
      font-style: normal;
      color: rgba($black, 0.5);
    }
    b {
      display: block;
      font-family: "Lato Bold";
      font-size: 1.4rem;

      &.highlight {
        cursor: pointer;
        font-size: 2rem;
        color: $green;
      }
    }
  }
}

.responsive-multiform-wrapper {
  @include media-layout("form_breakpoint") {
    input:not([type="checkbox"]):not([type="submit"]):not([type="file"]) {
      width: 100%;
    }

    select,
    .choices .choices__inner,
    .checkbox-container,
    .fileupload-container {
      width: 100% !important;
    }

    .form-buttons-container {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    .form-main-content {
      padding: 40px !important;
    }

    .form-header {
      flex-wrap: wrap;
      row-gap: 5px;

      h3 {
        margin-right: 20px;
      }
    }

    .fileupload-container {
      .fileupload-button-container {
        button {
          padding: 0.6rem 2rem;
          width: 100% !important;
        }

        .fileupload-select {
          width: 100%;
        }
      }

      .fileupload-list .file {
        width: 100%;
      }

      .fileupload-label .fileupload-dropzone {
        span {
          font-size: 1.6rem;
        }
      }
    }

    .centered-wrapper {
      max-width: 488px;
      width: 100%;
    }

    .form-main-content {
      > .checkbox-div:last-child {
        margin-bottom: 0;

        .checkbox-container {
          margin-bottom: 0;
        }
      }

      .checkbox-div {
        padding-left: 0;
      }
    }
  }

  @include media-layout(610px) {
    .captcha-container {
      flex-wrap: wrap;
      margin-top: 0;
      justify-content: flex-start;

      & > input {
        order: 2;
        margin-top: 20px;
      }

      .captcha-main {
        flex-basis: 100%;
        order: 1;
        align-items: stretch;

        img {
          object-fit: contain;
          flex-grow: initial;
        }
      }
    }
  }

  @include media-layout("mobile") {
    .form-buttons-container {
      button {
        font-size: 1.6rem;
        padding: 0.75rem 1.5rem;
      }
    }

    .form-details {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 3rem;
      margin-bottom: 30px;
    }

    .form-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .file-container {
      font-size: 1.2rem !important;
    }
  }
}

.list-item {
  position: relative; /* For positioning the menu */
}

.menu-button-container {
  position: relative;
  cursor: pointer;
  width: 56px;
  height: 62px;
  border: 1px solid #ffffff;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f4f4f4;
  }
}

.menu-button {
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border: 1px solid $light-gray;
  border-radius: 6px;
  box-shadow: 0px 3px 6px $light-green;
  padding: 5px 0;
  list-style: none;
  margin: 0;
  display: none;
  z-index: 9999;
  width: auto;
  min-width: 140px;

  li {
    padding: 8px 15px;
    cursor: pointer;
    font-family: "Lato Medium";
    font-size: 1.4rem;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &.show {
    display: block;
  }
}
