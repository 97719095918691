.tippy-box {
  .tippy-content {
    max-width: 380px;
    width: 100%;
    overflow-wrap: break-word;
    .fields {
      font-size: 1.4rem;
      color: #ffffff;
      width: 360px;

      .field {
        display: flex;
        justify-content: center;
        gap: 1rem;
        i {
          flex: 1;
          font-family: "Lato Light";
          text-align: right;
          max-width: calc(50% - 0.5rem);
          font-style: normal;
        }

        b {
          flex: 1;
          font-family: "Lato Bold";
          text-align: left;
          max-width: calc(50% - 0.5rem);
          overflow-wrap: break-word;
        }
      }
    }
  }
}
