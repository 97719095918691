@import "variables";
@import "_mixins";

%h1 {
  @include flex-centered-items;
  font-family: "Lato Regular";
  color: $black;
  font-size: 2rem;
  margin-top: 0;

  .back-btn,
  .back-btn-imitation {
    display: flex;
    text-decoration: none;
    background-color: #ffffff;
    color: $blue;
    padding: 0.75rem 1rem;
    margin-right: 1rem;
    border: 2px solid transparent;
    border-radius: 3px;

    &:hover {
      color: $black;
      border: 2px solid $green;
    }
  }

  .view-category {
    position: relative;
    font-family: "Lato Black";
    font-size: 3rem;
    color: $green;
    text-transform: uppercase;
    margin-right: 40px;

    &:after {
      @include position-absolute($top: 4px, $right: -21px);
      content: "";
      width: 3px;
      height: calc(100% - 8px);
      background: rgba($green, 0.5);
    }
  }
}

.card {
  .card-header {
    display: flex;
    margin-bottom: 2rem;

    h1 {
      @include flex-centered-items;
      font-family: "Lato Regular";
      color: $black;
      font-size: 2rem;

      .back-btn {
        display: flex;
        text-decoration: none;
        background-color: #ffffff;
        color: $blue;
        padding: 0.75rem 1rem;
        margin-right: 1rem;
        border: 2px solid transparent;
        border-radius: 3px;

        &:hover {
          color: $black;
          border: 2px solid $green;
        }
      }

      .view-category {
        position: relative;
        font-family: "Lato Black";
        font-size: 3rem;
        color: $green;
        text-transform: uppercase;
        margin-right: 40px;

        &:after {
          @include position-absolute($top: 4px, $right: -21px);
          content: "";
          width: 3px;
          height: calc(100% - 8px);
          background: rgba($green, 0.5);
        }
      }
      @extend %h1;
    }
    .menu {
      @include flex-centered-items;
      margin-left: auto;
      .btn {
        font-size: 1.5rem;
        height: 40px;
        margin-left: 10px;
      }
    }
  }

  .card-body {
    background-color: $light-gray;
    padding: 2rem;
    box-shadow: 0px 3px 6px $shadow-color;
  }

  .card-body-detail {
    display: flex;
    background-color: $white;
    box-shadow: 0 30px 30px $light-green;
    border: 1px solid $border-gray;
    border-radius: 26px;
    margin: 30px 0 45px 0;
    max-height: 208px;

    .detail-field {
      flex: 1;
      padding: 41px 66px;
      small {
        font-family: "Lato Medium";
        font-size: 1.4rem;
      }
      p {
        margin: 0.6rem 0 0 0;
        line-height: 1.9rem;
        font-family: "Lato Bold";
        font-size: 1.6rem;

        &.error {
          font-size: 1.4rem;
          color: $red;
        }

        &.large {
          font-family: "Lato Regular";
          font-size: 3rem;
          text-transform: uppercase;
          line-height: 2.6rem;
        }

        &.scrollable {
          overflow-y: scroll;
          font-size: 1.4rem;
          line-height: 2.6rem;
          max-height: 110px;
        }

        &.editable {
          cursor: pointer;
          i.fa-pencil {
            opacity: 0;
          }

          &:hover {
            opacity: 0.7;
            i.fa-pencil {
              opacity: 1;
            }
          }
        }
      }
    }
    .trip-number {
      flex-basis: 10%;
    }
    .trip-detail,
    .optimization-detail {
      flex-basis: 90%;
      display: flex;
      align-items: flex-end;
      margin-bottom: 40px;
      padding-right: 40px;

      .detail-field {
        padding: 0;

        p {
          word-break: break-word;
          max-width: 370px;
        }
      }
    }
    .optimization-detail {
      flex-basis: 100%;
      padding-left: 40px;
      padding-right: 40px;

      > div:first-child {
        flex-shrink: 0;

        p {
          word-break: keep-all;
        }
      }
    }
  }

  .card-footer {
    background-color: #fefefe;
    padding: 2rem;
    box-shadow: 3px 2px 3px $shadow-color;
    border-radius: 0 0 2.7rem 2.7rem;
  }

  &.table-layout {
    .card-body,
    .card-body-detail {
      padding: 2rem 0;
    }
  }

  &.responsive-card {
    .card-body-detail {
      max-height: initial;

      > .detail-field:first-child {
        small {
          white-space: nowrap;
        }
      }

      .trip-detail,
      .optimization-detail {
        flex-wrap: wrap;
        row-gap: 30px;

        .detail-field {
          padding-right: 30px;

          small {
            white-space: nowrap;
          }
        }
      }

      @include media-layout("form_breakpoint") {
        .trip-detail {
          padding-top: 41px;
        }
      }

      @include media-layout("small") {
        flex-direction: column;

        > .detail-field:first-child {
          flex-basis: 100%;
          padding: 40px 40px 0 40px;
        }

        .trip-detail {
          flex-direction: column;
          flex-basis: 100%;
          align-items: flex-start;
          padding: 40px;

          .detail-field {
            padding: 0;
          }
        }
      }
    }

    @include media-layout("mobile") {
      .card-header {
        h1,
        .view-category {
          font-size: 1.8rem;
        }
      }
    }
  }
}
