@import "variables";
@import "mixins";

.alerts {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: 2rem;
  right: 9rem;
  z-index: 1000;

  .alert {
    background-color: $green;
    padding: 2rem 2.5rem;
    border: 3px solid #ffffff;
    border-radius: 6px;
    font-size: 1.6rem;
    color: $black;
    display: flex;
    align-items: center;

    &.hide {
      opacity: 0;
      transition: opacity 300ms;
    }

    .tag {
      font-family: "Lato Bold";
      margin-right: 0.5rem;
    }

    .text {
      font-family: "Lato Regular";
    }

    .btn-close {
      background: center no-repeat url(../images/close.svg);
      width: 32px;
      height: 32px;
      margin-left: auto;
      border: none;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    &.alert-success {
      background-color: #00ff81;
    }
    &.alert-error {
      background-color: #ffdada;
    }
    &.alert-warning {
      background-color: #f6ff7a;
    }
    &.alert-info {
      background-color: $blue;
      color: #ffffff;
    }
  }
  @include media-layout("mobile") {
    top: 70px;
    right: 0;

    &,
    .alert {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
