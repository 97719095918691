@import "_mixins";

@media (max-width: 1880px) {
  .diagrams-container {
    flex-direction: column;
  }

  .dashboard-detail {
    flex-direction: column;
  }
}

.form-dashboard-content {
  padding: 26px 159px;

  span {
    font-family: "Lato Black Italic";
    font-size: 1.8rem;
    opacity: 0.6;
  }
}

.form-dashboard-table {
  padding: 16px 49px;
}

.dashboard-large {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  -webkit-box-shadow: 0 30px 30px rgba(38, 127, 83, 0.2862745098);
  box-shadow: 0 30px 30px rgba(38, 127, 83, 0.2862745098);
  border: 1px solid #f1f2f4;
  border-radius: 26px;
  width: 95%;
  margin: 30px 0 45px 0;
  flex-direction: column;

  .dashboard-spinner-container {
    margin: 100px;
    align-self: center;
  }

  .diagrams-container {
    display: flex;
    overflow: auto;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 30px;
    gap: 30px;
  }

  .diagrams-window {
    border: 2px solid #1d1d1b33;
    border-radius: 5px;
    padding: 20px 20px;
    width: 600px;
    height: 340px;
  }

  .diagrams-title {
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 26px 159px;

    .title {
      display: flex;

      h1 {
        font-family: "Lato Black";
        font-size: 30px;
        font-size: 3rem;
        font-weight: normal;
        color: #267f53;
      }
    }
    .arrows-block {
      position: absolute;
      right: 0;
      display: inline-flex;
      margin-right: 40px;
    }
    .status-filter {
      position: absolute;
      right: 320px;
      color: $black;
      background: $white;
      border: 1px solid #e1dfdf;
      border-radius: 6px;
      padding: 0.75rem 1rem !important;

      &:hover {
        border: 1px solid $blue;
      }

      &:focus-visible {
        outline: none;
        border: 1px solid $blue;
      }
    }
  }

  input {
    border: 0;
    outline: 0;
    font-family: "Lato Black";
    font-size: 1.8rem;
    opacity: 0.6;
    background: none;
    cursor: pointer;
    text-align: center;
  }

  .map-input {
    border: 0;
    outline: 0;
    font-family: "Lato Black";
    font-size: 1.8rem;
    opacity: 0.6;
    background: none;
    cursor: pointer;
    text-align: center;
  }
}

.dashboard-detail {
  display: table;
  width: 100%;

  .form-container {
    padding: 0;
    box-shadow: 0px 30px 30px #267f534a;
  }

  .data-cell-label {
    display: table-cell;
    font-family: "Lato Black";
    text-align: center;
    font-size: 1.6rem;
    padding-bottom: 5px;
    opacity: 0.6;
    border-bottom: 1px solid #1d1d1b99;
  }

  .data-cell-content {
    display: table-cell;
    font-family: "Lato Regular";
    text-align: center;
    font-size: 1.6rem;
    padding-bottom: 10px;
    padding-top: 10px;
    opacity: 0.6;
    border-bottom: 1px solid #1d1d1b99;
  }

  .data-cell {
    display: table-row-group;
    margin-bottom: 5px;
  }

  .date-block {
    display: inline-flex;
    gap: 70px;
    padding: 19px 49px;

    span {
      font-family: "Lato Black";
      font-size: 1.7rem;
      opacity: 0.6;
      cursor: pointer;
    }

    .arrows-block {
      display: inline-flex;
    }
  }
}

.arrow {
  display: block;
  width: 0px;
  height: 0px;
  border: 8px solid transparent;
  margin-top: 2px;
  cursor: pointer;

  &.arrow-left {
    border-right-color: #1d1d1b99;
    border-left-width: 0px;
  }

  &.arrow-right {
    border-left-color: #1d1d1b99;
    border-right-width: 0px;
  }
}
