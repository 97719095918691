@import "fonts";
@import "variables";
@import "layout";
@import "buttons";
@import "cards";
@import "tabulator";
@import "forms";
@import "modals";
@import "checkbox";
@import "alerts";
@import "routes_edit";
@import "errors";
@import "progressbar";
@import "tooltips";
@import "prompts";
@import "files";
@import "gantt";
@import "split_slider";
@import "dashboard";
@import "notifications";

@import "~flatpickr/dist/flatpickr.css";
@import "~nouislider/dist/nouislider.css";

html {
  font-size: 10px;
  font-family: "Lato Regular";
  scroll-behavior: smooth;
  scroll-padding-top: 50px;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-size: 2rem;
  background-color: $white;
  color: $black;
  overflow-x: hidden;
}

body.no-scroll {
  overflow: hidden;
}
