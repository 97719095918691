@import "variables";
@import "_mixins";

.btn {
  font-family: "Lato Bold";
  font-size: 1.8rem;
  border-radius: 3rem;
  border: 2px solid transparent;
  padding: 0.5rem 3rem;
  cursor: pointer;
  transition: 200ms ease;
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-black {
  background-color: $black;
  color: $white;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.btn-outline-black {
  @include button-outline;
}

.btn-green {
  background-color: $green;
  color: $white;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.btn-outline-green {
  @include button-outline($green);
}

.btn-blue {
  background-color: $blue;
  color: $white;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.btn-outline-blue {
  @include button-outline($blue);
}

.btn-red {
  background-color: $red;
  color: $white;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.btn-outline-red {
  @include button-outline($red);
  color: $black;
}

.btn-large {
  font-family: "Lato Bold";
  font-size: 3.2rem;
  padding: 1rem 8rem;
}

.btn-submit {
  @include flex-full-centered-items;
  @include border-radius(30px);
  padding: 0.75rem 3.5rem;
  font-family: "Lato Bold";
  font-size: 1.8rem;
  color: #fff;
  background: $black;
  border: 3px solid transparent;
  box-shadow: none;
  align-self: flex-end;

  &:hover {
    background: $blue;
    cursor: pointer;
  }
}

.btn-submit-alt {
  @include flex-full-centered-items;
  @include border-radius(30px);
  padding: 0.75rem 3.5rem;
  font-family: "Lato Bold";
  font-size: 1.8rem;
  color: #000000;
  background: transparent;
  border: 3px solid #000000;
  box-shadow: none;
  align-self: flex-end;

  &:hover {
    color: #ffffff;
    border-color: $blue;
    background: $blue;
    cursor: pointer;
  }
}

.btn-secondary-form {
  font-family: "Lato Regular";
  font-size: 1.4rem;
  color: $green;
  border-bottom: 1px solid $green;
  text-decoration: none;

  &:hover {
    color: rgba($blue, 0.7);
    border-bottom: 3px solid $blue;
  }
}

.captcha-buttons {
  .circle-btn {
    @include flex-full-centered-items;
    @include border-radius(50%);
    width: 38px;
    height: 38px;
    border: 0;
    color: #fff;
    background: $black;

    &:hover {
      background: $blue;
      cursor: pointer;
    }
  }
}

.btn-table-full,
.btn-table-full-detail {
  @include flex-inline-centered;
  @include border-radius(3rem);
  font-family: "Lato Regular";
  font-size: 1.7rem;
  color: #fff;
  padding: 4px 28px;
  background: $black;
  text-decoration: none;
  border: 2px solid $black;
  cursor: pointer;

  &:hover {
    background: $green;
    border: 2px solid $green;
  }

  &:visited {
    color: #fff !important;
  }
}

.btn-table-full-detail {
  font-family: "Lato Bold";
  padding: 9px 28px;
  margin-bottom: 47px;
}

.btn-table-outline {
  @extend .btn-table-full;
  border-color: $black;
  background: 0;
  color: $black;

  &:hover {
    border-color: $green;
    background: 0;
  }
}

.btn-header {
  height: 48px;
  border-radius: 30px;
  border: 0;
  box-shadow: none;
  &:hover {
    background: $blue;
  }
}

.btn-copy_url {
  @extend .btn-header;
  color: white;
  background: $green;
}

.btn-filter {
  @extend .btn-header;
  color: $black;
  background: white;
  border: 3px solid $black;
  padding-left: 69px;
  padding-right: 69px;
  &:hover {
    color: white;
    border-color: $blue;
  }
}

.btn-secondary {
  @extend .btn-submit;
  background: transparent !important;
  color: $black !important;
  text-decoration: none;
  font-size: 1.8rem;
  &:hover {
    background: $green !important;
    color: #fff !important;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: transparent !important;
      color: $black !important;
    }
  }
}

%btn-table-action-basic {
  @include border-radius(3rem);
  font-family: "Lato Bold";
  font-size: 1.8rem;
  text-decoration: none;
  padding: 8px 57px;
  box-sizing: border-box;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-table-action-outline {
  @include button-outline($black);
  @extend %btn-table-action-basic;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: $blue;
    border-color: $blue;
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.btn-table-action-outline-small {
  @extend .btn-table-action-outline;
  font-size: 1.6rem;
}

.btn-table-action {
  @extend %btn-table-action-basic;
  border: 2px solid $green;
  color: #fff;
  background: $green;

  &:hover:not(:disabled) {
    background: $blue;
    border-color: $blue;
    cursor: pointer;
  }
}

.btn-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.btn-date-clear {
  @include border-radius(50%);
  @include position-absolute($top: 3px, $right: 3px);
  content: "\f00d";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  width: 32px;
  height: 32px;
  padding: 0;
  background: $light-blue;
  color: $blue;
  box-shadow: none;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $black;
    color: #ffffff;
  }
}

.token-actions button {
  @include flex-inline-full-centered;
  @include border-radius(3px);
  color: #fff;
  background: $black;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  width: 25px;
  height: 25px;

  &:hover {
    background: $green;
  }
}

.btn-start-optimize {
  background-color: $black;
  color: #ffffff;

  &:hover,
  &:focus {
    background-color: $green;
  }
  &:disabled {
    background-color: #ffffff;
    color: rgba($black, 0.3);
  }
}

.btn-start-manual-optimize {
  background-color: white;
  border-color: black;

  &:hover,
  &:focus {
    border-color: $green;
    background-color: $green;
    color: #ffffff;
  }
  &:disabled {
    border-color: #ffffff;
    background-color: #ffffff;
    color: rgba($black, 0.3);
  }
}

.btn-green-hover-blue {
  font-family: "Lato Regular";
  font-size: 1.6rem;
  border-radius: 30px;
  padding: 0.6rem 7rem;
  border: none;
  color: #fff;
  background: $green;
  transition: background-color 0.2s ease-in-out;

  &:hover:not(:disabled) {
    background: $blue;
    cursor: pointer;
  }
}

.btn-link {
  font-family: "Lato Medium";
  font-weight: normal;
  font-size: 1.6rem;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: $blue;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0.8rem 2.2rem;
  border-radius: 30px;

  i {
    margin-left: 0.5rem;
  }

  &:hover {
    color: #ffffff;
    background-color: $green;
  }
}

.switch-view-container {
  button {
    @include flex-full-centered-items;
    @include border-radius(2px);
    width: 60px;
    height: 60px;
    border: 3px solid transparent;
    background: #fff;

    &:hover {
      cursor: pointer;
    }

    &.active {
      border-color: $light-blue;

      .icon {
        opacity: 1;
      }
    }

    &:first-child,
    &:nth-child(2) {
      margin-right: 3px;
    }

    .icon {
      font-size: 2.5rem;
      opacity: 0.5;
    }
  }
}

.radio-btn {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.radio-label {
  vertical-align: middle;
}
